import {Component} from "react";
import SignResult from '../signResult/signResult'
import Verify from '../verify/verify'
import ContinueVerify from '../continueVerify/continueVerify'
import CreateResume from '../createResume/createResume'
import btnClick from "../../../assets/click1.wav";
const audio = new Audio(btnClick);

class signPop extends Component {
    constructor(props) {
        super();
        this.state={
            // showNum:3,
            showNum: props.num ? props.num : 1,     //1:报名信息确认,2:报名结果,3:识别身份证后，继续验证,4:手动创建
            sn: props.sn,
            poisitinInfoData: props.poisitinInfoData,
            userInfoData: props.userInfoData,
            deliver: {},
            verify: {},
            mobile:'',
            comeNum:null
        }
    }

    //点击找工作声音
    btnClickVoice(){
        audio.play();
    }
    // 扫脸 回调
    onDeliverSuccess = (res,mobile) => {
        this.btnClickVoice()
        this.setState({
            showNum: 2,
            deliver: res,
            mobile:mobile
        })
    }

    // 扫描身份证 回调
    onVerifySuccess = (res) => {
        this.btnClickVoice()
        this.setState({
            showNum: 1,
            userInfoData: res,
            comeNum:3
        })
    }

    render() {
        return (
            <div style={{  borderRadius: '20px',height:'80vh'}}>
                {
                    this.state.showNum==1?(
                        <Verify
                            sn={this.state.sn}
                            poisitinInfoData={this.state.poisitinInfoData} 
                            userInfoData={this.state.userInfoData}
                            comeNum={this.state.comeNum}
                            onUserInfoBack={this.props.onUserInfoBack}
                            onDeliverSuccess={this.onDeliverSuccess}
                        >
                        </Verify>
                    ):('')
                }
                {
                    this.state.showNum==2?(
                        <SignResult deliver={this.state.deliver} onUserInfoBack={this.props.onUserInfoBack} mobile={this.state.mobile}></SignResult>
                    ):('')
                }
                {
                    this.state.showNum==3?(
                        <ContinueVerify 
                            idCardInfoData={this.props.idCardInfoData} 
                            onUserInfoBack={this.props.onUserInfoBack}
                            onVerifySuccess={this.onVerifySuccess}
                        ></ContinueVerify>
                    ):('')
                }
                {
                    this.state.showNum==4?(
                        <CreateResume ></CreateResume>
                    ):('')
                }
            </div>
        );
    }
}
export default signPop;