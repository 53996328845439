import React, { useState, useEffect,useRef } from 'react';
import screeingList from '../../utils/screeningList.js'
import { Button } from 'react-vant';
import style from './screen.module.less';


const Screen = ({ selectScreenData, onData }) => {

    const [screenData, setScreenData] = useState({
        workingLife: '',    // 工作性质
        education: '',    // 学历要求
        salary: '',    // 薪资要求
    })
    const typeList = localStorage.getItem('positionTypeInt');
    const workliftList = screeingList.workliftList; // 工作经验
    const eduList = screeingList.eduList; // 学历要求
    const salaryList = screeingList.salaryList; // 薪资范围

    useEffect(() => {
        setScreenData({
            workingLife: selectScreenData.workingLife,    // 工作性质
            education: selectScreenData.education,    // 学历要求
            salary: selectScreenData.salary,    // 薪资要求
        })
    },[])
    
    
    // 点击清除
    const handleClean = () => {
        setScreenData({
            workingLife: '',    // 工作性质
            education: '',    // 学历要求
            salary: '',    // 薪资要求
        })
    }

    // 点击确定
    const handleConfirm = () => {
        onData(screenData);
        console.log(screenData);
    }
    
    return (
        <div className={style.screenWrap}>
            <div className={style.list}>

                {/* 工作经验 */}
                {typeList != 3 && (
                    <div className={style.selector}>
                        <div className={style.screenHeadline}>工作经验</div>
                        <div className={style.contentWrap}>
                            {workliftList.map((item, index) => {
                                return (
                                        <div 
                                            className={ screenData.workingLife == item.value ? `${style.item} ${style.active}` : style.item} 
                                            key={index} 
                                            onClick={() => screenData.workingLife = item.value }
                                        >{ item.name }</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )}

                {/* 学历要求 */}
                <div className={style.selector}>
                    <div className={style.screenHeadline}>学历要求</div>
                    <div className={style.contentWrap}>
                        {eduList.map((item, index) => {
                            return (
                                    <div 
                                        className={ screenData.education == item.value ? `${style.item} ${style.active}` : style.item} 
                                        key={index} 
                                        onClick={() => screenData.education = item.value }
                                    >{ item.name }</div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* 薪资月薪 */}
                {typeList != 2 && (
                    <div className={style.selector}>
                        <div className={style.screenHeadline}>薪资月薪</div>
                        <div className={style.contentWrap}>
                            {salaryList.map((item, index) => {
                                return (
                                        <div 
                                            className={ screenData.salary == item.value ? `${style.item} ${style.active}` : style.item} 
                                            key={index} 
                                            onClick={() => screenData.salary = item.value }
                                        >{ item.name }</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )}
            </div>

            <div className={style.bottom}>
                <Button type='primary' className={style.cleanBtn} onClick={handleClean}>清除</Button>
                <Button type='primary' className={style.confirmBtn} onClick={handleConfirm}>确定</Button>
            </div>
        </div>
    );
};

export default Screen;