import React, {Component} from "react";
import createStyle from './createResume.module.less'
import { Radio } from 'antd';
class createResume extends Component {
    constructor() {
        super();
        this.state={
            itemObj:{
                tel:'',
                code:'',
                name:''
            },
            codeNum:null,
            txt:'发送',
            btnShow:true,
            grayColor:false
        }
    }
    sendCode=()=>{
        if (!this.state.codeNum){
            this.state.codeNum=60
            let timer=setInterval(()=>{
                if(this.state.codeNum==0){
                    clearInterval(timer)
                    this.setState({
                        codeNum:null,
                        txt:'重新发送'
                    })
                }else{
                    let i=this.state.codeNum-1
                    this.setState({
                        codeNum:i
                    })
                }
            },1000)
        }
    }
    codeChange=(e)=>{
        let str=this.state.itemObj.code
        if (str){
            str=str.slice(-1,str.length-1)
            str=str+e
        }else {
            str=e
        }
        this.setState({
            itemObj:{
                ...this.state.itemObj,
                code:str
            }
        })
    }
    telChange=(e)=>{
        let str=this.state.itemObj.tel
        if (str){
            str=str.slice(-1,str.length-1)
            str=str+e
        }else {
            str=e
        }
        this.setState({
            itemObj:{
                ...this.state.itemObj,
                tel:str
            }
        })
    }
    nameChange=(e)=>{
        let str=this.state.itemObj.name
        if (str){
            str=str.slice(-1,str.length-1)
            str=str+e
        }else {
            str=e
        }
        this.setState({
            itemObj:{
                ...this.state.itemObj,
                name:str
            }
        })
    }
    handleFocus=(event)=>{
        this.setState({
            btnShow:false,
        })
    }
    handleBlur=()=>{
        let flag=false
        if(this.state.itemObj.tel&&this.state.itemObj.code&&this.state.itemObj.name){
            flag=true
        }
        this.setState({
            btnShow:true,
            grayColor:flag

        })
    }
    render() {
        return (
            <div className={createStyle.createResume}>
                <h1>手动创建简历报名</h1>
                <article>
                    <aside>
                        <p>姓名</p>
                        <section>
                            <input type="text" placeholder="请输入姓名" value={this.state.itemObj.name} onFocus={this.handleFocus} onBlur={this.handleBlur}
                                   onChange={(event)=>this.nameChange(event.target.value)}/>
                        </section>
                    </aside>
                    <aside>
                        <p>手机号</p>
                        <section>
                            <input type="number" placeholder="请输入手机号" value={this.state.itemObj.tel} onFocus={this.handleFocus} onBlur={this.handleBlur}
                                   onChange={(event)=>this.telChange(event.target.value)}/>
                        </section>
                    </aside>
                    <aside>
                        <p>验证码</p>
                        <section>
                            <input className={createStyle.code} type="number" placeholder="请输入验证码" value={this.state.itemObj.code}
                                   onFocus={this.handleFocus} onBlur={this.handleBlur} onChange={(event)=>this.codeChange(event.target.value)}/>
                            {
                                this.state.codeNum?(
                                    <p>已发送{this.state.codeNum}s</p>
                                ):(
                                    <button onClick={()=>this.sendCode()}>{this.state.txt}</button>
                                )
                            }
                        </section>
                    </aside>
                    <aside>
                        <p>登陆密码</p>
                        <section>初始密码为手机号后6位，请及时修改</section>
                    </aside>
                </article>
                <div className={createStyle.agree}>
                    <Radio className={createStyle.radioBtn}>
                        您已阅读并同意
                        <span className={createStyle.strBlue}>《隐私协议》</span>
                        和
                        <span className={createStyle.strBlue}>《用户协议》</span>
                    </Radio>
                </div>
                {
                    this.state.btnShow?(
                        <div className={createStyle.btn}>
                            <aside>
                                <button className={createStyle.cancel}>取消</button>
                                <button className={this.state.grayColor?createStyle.send:createStyle.grayCol}>下一步</button>
                            </aside>
                        </div>
                    ):('')
                }
            </div>
        );
    }
}

export default createResume;