import React, {Component} from 'react';
import style from './other.module.less'
import back from '../../assets/img/next.png'
class OtherLink extends Component {
    back=()=>{
        // window.history.back()
        window.close()
    }
    render() {
        return (
            <div className={style.link}>
                <div className={style.next} onClick={this.back}>
                    <img src={back} alt=""/>
                </div>
                <iframe src="https://ccper.rencaidanao.com/allinone"  width="100%" height="100%" frameborder="0"></iframe>
            </div>
        );
    }
}

export default OtherLink;