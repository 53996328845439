import {Component} from "react";
import sign from './signResult.module.less'
import completeImg from '../../../assets/img/home/complete.png'
import errorImg from '../../../assets/img/home/error.png'
import btnClick from "../../../assets/click1.wav";
import {ChatApi} from '../../../services/chatApi.js';

import { connect } from 'react-redux';
import {setTimerType, clearTimerType, setBroadKey} from '../../../store/actions'
const audio = new Audio(btnClick);

const chatApi = new ChatApi();

class signResult extends Component {
    constructor(props) {
        super();
        this.state = {
            deliver: props.deliver,
            mobile:props.mobile,
            displayName: ""
        }
    }
    componentDidMount() {

        //获取该用户小程序名称
        let display_name = localStorage.getItem("WJ_DISPLAY_NAME");

        if(display_name){
            this.setState({
                displayName: display_name
            })
        }

        this.props.setTimerType("signResult");
        if (this.state.deliver.data.code == 200 || this.state.deliver.data.code == '200'){

            let broadText = "恭喜你，报名成功，请注意短信或者电话通知。更多详情也可以登陆支付宝小程序：" + display_name +"进行查看。"

            chatApi.getChatData(broadText).then(res => {

                if(res.data && res.data.voiceBroadcastResult){
                    let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)
                    window.Player.current.setData(voiceData);
                }
            })
        }else {
            this.props.setBroadKey("defeat")
        }




    }
    componentWillUnmount() {
        this.props.clearTimerType();
    }
    // 返回
    handleBack = () => {
        this.btnClickVoice()
        this.props.onUserInfoBack(1);
    }

    handleToRecord = () => {
        this.btnClickVoice()
        this.props.onUserInfoBack(4,this.state.mobile);
    }
    btnClickVoice(){
        audio.play();
    }
    render() {
        return (
            <div className={sign.signResult}>
                {this.state.deliver.data.code == 200 || this.state.deliver.data.code == '200' ? (
                    <aside>
                        <div className={sign.inform}>
                            <img src={completeImg} alt=""/>
                            <p>报名成功</p>
                        </div>
                        <div className={sign.person}>
                            <aside>查看后续进度</aside>
                            <p className={sign.numTxt}>
                                <b>1</b>
                                通过<span>短信或电话</span>提醒您
                            </p>

                            {
                                this.state.displayName ? (
                                    <p className={sign.numTxt}>
                                        <b>2</b>
                                        登录 <span>支付宝小程序【{this.state.displayName}】</span>查看
                                    </p>
                                ):('')
                            }


                        </div>
                    </aside>
                ):(
                    <aside>
                        <div className={`${sign.inform} ${sign.lose}`}>
                            <img src={errorImg} alt=""/>
                            <p>报名重复</p>
                        </div>
                        <div className={sign.person}>
                            {/*<p>失败原因：{ this.state.deliver.data.msg }</p>*/}
                            <p>您已报名过该职位，不能重复报名哦～</p>
                        </div>
                    </aside>
                )}
                
                <div className={sign.btn}>
                    <aside>
                        <button className={sign.check} onClick={ this.handleToRecord }>查看报名记录</button>
                        <button className={sign.over} onClick={ this.handleBack }>好的</button>
                    </aside>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    setTimerType: (timerType) => {
        dispatch(setTimerType(timerType))
    },
    clearTimerType: () => dispatch(clearTimerType()),
    setBroadKey: (broadKey) => {
        dispatch(setBroadKey(broadKey))
    },
});
export default connect(null, mapDispatchToProps)(signResult);
// export default signResult;