import { applyMiddleware, legacy_createStore } from 'redux'
import  { thunk } from 'redux-thunk';
// 引入reducer
import reducers from './reducers.js'

// 创建store实例
let store = legacy_createStore(
    reducers,
    applyMiddleware(thunk)
)
export default store