// 外包派遣
import React, { useState, useEffect  } from 'react';
import styles from './outsourcing.module.less';
import filters from '../../utils/filters.js';
import '../../assets/iconfont/iconfont.css'
import hotIcon from '../../assets/img/urgent.png';
import {PositionApi} from "../../services/positionApi.js";
import style from "./directAdmission.module.less";
import PageLoading from '../speakLoading/pageLoading'

import { useDispatch } from 'react-redux';
import {setTimerType, clearTimerType} from '../../store/actions'
import {CSSTransition} from "react-transition-group";
import '../test/test.css';

const Outsourcing = ({poisitinInfoData,toAsk}) => {
    let positionAi = new PositionApi();
    const positionHroId = poisitinInfoData.positionId?poisitinInfoData.positionId:poisitinInfoData.positionHroId;
    const [loading, setLoading] = useState(false);

    const [details, setDetails] = useState({});
    const [showCon, setShow] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setTimerType("positionInfo"))
        return () => {
            dispatch(clearTimerType())
        }
    }, []);
    useEffect(() => {
        getPositionInfo(); // 获取数据的函数
    }, [positionHroId]);
    
    const getPositionInfo = () => {
        setLoading(true);
        let params = {
            positionHroId: positionHroId
        }
        positionAi.getOutDetail(params).then((res) => {
            // console.log(res, '职位详情');
            if(res.data.data) {
                setDetails(res.data.data);
            }
            setLoading(false);
            setTimeout(()=>{
                setShow(!showCon)
            },500)
        })
    }

    function skillFlag() {}

    function projectFlag() {}
    const toAskQuestion=(e)=>{
        let arr=['这个岗位的工资是多少，有没有加班费','每天工作多长时间，周末能休息吗','公司提供住宿吗，住宿条件怎么样','具体需要做些什么工作，这个岗位需要什么技能']
        let str=arr[e]
        toAsk(str)
    }
    return (
        <div className={style.positionWrap}>
            {
                loading ? (
                    <div className={style.loading}>
                        <PageLoading></PageLoading>
                    </div>
                ) : (
                    <div className={style.detailHeight}>
                        <CSSTransition in={showCon} timeout={300} classNames={{
                            enter: "alert-enter",
                            enterActive: "alert-enter-active",
                            exit: "alert-exit",
                            exitActive: "alert-exit-active",
                        }} unmountOnExit>
                            <div className={styles.detail} >
                                <div className={styles.box} >
                                    <div className={styles.header}>
                                        <div className={styles.title}>{details.positionName}</div>
                                        <img src={hotIcon} className={styles.urgent} style={{ display: details.isUrgent === 1 ? 'block' : 'none' }} alt="" />
                                        <div className={styles.money} style={{ display: details.minSalary && details.maxSalary ? 'block' : 'none' }}>{details.minSalary}-{details.maxSalary} 元/月/人</div>
                                    </div>
                                    <article>
                                        <div className={styles.str}>
                                            <b className={styles.strCompany}>
                                                {details.enterpriseName && <i className="iconfont icon-gongzuojingyan"></i>}{details.enterpriseName}
                                            </b>
                                            <b>| 招{details.employeeNum}人</b>
                                            <b>| {filters.getPotitionType(details.positionNature)}</b>
                                            <b>| {details.digEducationStr ? details.digEducationStr : '学历不限'}</b>
                                            <b> | { filters.getExperience(details.workingLife) }</b>
                                        </div>
                                        <div className={styles.str}>
                                            <i className="iconfont icon-adress"></i>
                                            <b>{details.digWorkPlace && details.digWorkPlace}</b>
                                        </div>
                                    </article>
                                </div>
                                <div className={styles.question}>
                                    <h1>您可以问我以下问题：</h1>
                                    <button onClick={()=>toAskQuestion(0)}>岗位的工资是多少</button>
                                    <button onClick={()=>toAskQuestion(1)}>工作时间是怎样的</button>
                                    <button onClick={()=>toAskQuestion(2)}>公司提供住宿吗</button>
                                    <button onClick={()=>toAskQuestion(3)}>工作有什么要求？</button>
                                </div>
                                <div className={styles.tap_content} >
                                    <div className={styles.demand}>
                                        <div className={styles.box1}>
                                            <div  className={styles.broBottom}>
                                                <div className={`${styles.card_2}`}>
                                                    <div className={styles.title}>
                                                        职位详情
                                                    </div>
                                                </div>
                                                <div className={`${styles.card} ${styles.detailTxt}`}>
                                                    <div className={styles.title}>岗位类别:</div>
                                                    <div className={styles.content}>{details.jobName}</div>
                                                </div>
                                                <div className={`${styles.card} ${styles.detailTxt}`} >
                                                    <div className={styles.title}>年龄范围:</div>
                                                    <div className={styles.content}>{details.minAge}-{details.maxAge}岁</div>
                                                </div>
                                                <div className={`${styles.card} ${styles.detailTxt}`} style={{ display: details.minEduStr && details.maxEduStr ? 'block' : 'none' }}>
                                                    <div className={styles.title}>学历水平:</div>
                                                    <div className={styles.content}>{details.minEduStr}-{details.maxEduStr}</div>
                                                </div>
                                                <div className={`${styles.card} ${styles.detailTxt}`}>
                                                    <div className={styles.title}>工作经验:</div>
                                                    <div className={styles.content}>{ filters.getExperience(details.workingLife) }</div>
                                                </div>
                                                <div className={`${styles.card} ${styles.detailTxt}`}>
                                                    <div className={styles.title}>招聘周期:</div>
                                                    <div className={styles.content}>{ filters.getDateShowText(details.minTime, 'yyyy.MM.dd')} - { filters.getDateShowText(details.maxTime, 'yyyy.MM.dd')}</div>
                                                </div>
                                            </div>
                                            <div className={`${styles.card_2} ${styles.broBottom}`}>
                                                <div className={styles.title}>
                                                    技能描述:
                                                </div>
                                                <div className={styles.text} >
                                                    {details.skillDesc}
                                                </div>
                                                {/* <div className={styles.more} onClick={() => getMore(1)} style={{ display: skillFlag ? 'block' : 'none' }}>
                                <more></more>
                            </div> */}
                                            </div>
                                            <div className={`${styles.card_2} ${styles.broBottom}`}>
                                                <div className={styles.title}>
                                                    项目介绍:
                                                </div>
                                                <div className={styles.text} >
                                                    {details.projectDesc}
                                                </div>
                                                {/* <div className={styles.more} onClick={() => getMore(2)} style={{ display: projectFlag ? 'block' : 'none' }}>
                                <more></more>
                            </div> */}
                                            </div>
                                        </div>
                                        <div className={styles.box1}>
                                            <div className={styles.broBottom}>
                                                <div className={styles.main_title}>公司信息</div>
                                                <div className={styles.card}>
                                                    <div className={styles.title}>
                                                        公司性质:
                                                    </div>
                                                    <div className={styles.content}>
                                                        { filters.getNatureText(details.nature)}
                                                    </div>
                                                </div>
                                                <div className={styles.card}>
                                                    <div className={styles.title}>
                                                        所属行业:
                                                    </div>
                                                    <div className={styles.content}>
                                                        {details.industryName ? details.industryName : '未填写'}
                                                    </div>
                                                </div>
                                                <div className={styles.card}>
                                                    <div className={styles.title}>
                                                        公司规模:
                                                    </div>
                                                    <div className={styles.content}>
                                                        { filters.getDefaultValue(details.orgScale, '未填写')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.card_2}>
                                                <div className={styles.title}>
                                                    公司介绍:
                                                </div>
                                                <div className={styles.text}>
                                                    {details.orgIntroduction ? details.orgIntroduction : '未填写'}
                                                </div>
                                                {/* <div className={styles.more} onClick={() => getMore(3)} style={{ display: orgInformFlag ? 'block' : 'none' }}>
                                <more></more>
                            </div> */}
                                            </div>
                                        </div>
                                        <article className={styles.blockHeight}></article>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                )
            }
        </div>
    )
}
export default Outsourcing;