import React, { useState, useEffect } from 'react';
import styles from './styles.module.less'
// import './script.js'
import { Button, ProgressBar, Space } from 'antd-mobile'
import logoIcon from '../../assets/img/bgIndex.png'

const Loading = (props) => {
    const [showLoading, setShowLoading] = useState(true);
    const [progress, setProgress] = useState(10);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        animateProgress();
    }, [props.loadingBtnFlag]);

    const animateProgress = () => {
        let target = 99;
        const duration = 7500; // 7 seconds
        const startTime = new Date().getTime();

        const animate = () => {
            const now = new Date().getTime();
            const elapsed = now - startTime;
            const progressValue = Math.min(1, elapsed / duration);

            setProgress(Math.round(easing(progressValue) * (target - 10) + 10));
            let el = document.getElementById('fileBar');
            if (el){
                el.style.width = Math.round(easing(progressValue) * (target - 10) + 10) + '%';
            }

            if (progressValue < 1) {
                requestAnimationFrame(animate);
            } else {
                setProgress(99);
                setShowBtn(props.loadingBtnFlag);
                // setShowBtn(false)
            }
        };

        animate();
    };

    const easing = (progressValue) => {
        return -0.5 * (Math.cos(Math.PI * progressValue) - 1);
    };

    const handleToLook = () => {
        setShowLoading(false);
        props.onCloseLoading();
    };
    
    return (
        <div className={styles.loadingWrap} id='loadingWrap'>
            <div className={styles.loadingImg}>
                <img src={ logoIcon } alt="" />
                <p className={styles.tipsInfo}>欢迎使用服务助手小顾</p>
            </div>
            <div className={styles.lineLoad}>
                { !showBtn ? (
                    <div id="fileProgress" className={styles.myProgress}>
                        <div id="fileBar" className={styles.myBar}>{progress}%</div>
                    </div>
                ):(
                    <Button block color='primary' size='middle' onClick={handleToLook}>
                        开始探索
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Loading;