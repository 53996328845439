import React, { useState, useEffect } from 'react';
import styles from './style.module.less'
import '../../assets/iconfont/iconfont.css'
import card from '../../assets/img/card.png'
import notCard from '../../assets/img/notCard.png'
import errorImg from '../../assets/img/home/error.png'
import { useDispatch } from 'react-redux';
import {clearTimerType, setBroadKey, setTimerType} from "../../store/actions";

const ScanIdCard = (props) => {
    const [cardStatus, setCardStatus ] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setCardStatus(props.scanStatus);
    },[props.scanStatus])

    const handleAgain = () => {
        props.onAgain();
    }
    useEffect(() => {
        dispatch(setTimerType("scanIdCard"))

        dispatch(setBroadKey('scanIdCard'))

        if(cardStatus != null){
            if (cardStatus){
                dispatch(setBroadKey('swipeID'))
                // alert('swipeID');
            }else {
                dispatch(setBroadKey('swipeIDDefeat'))
                // alert('swipeIDDefeat');
            }
        }


        return () => {
            dispatch(clearTimerType())
        }

    }, []);

    return (
        <div className={styles.idCard}>
            {
                cardStatus ? (
                    <div className={styles.content}>
                        <aside>
                            <img src={card} alt="" />
                        </aside>
                        <p>请放置身份证在扫描区</p>
                    </div>
                ):(
                    <div className={styles.conH}>
                        <div className={styles.content}>
                            <aside>
                                <img src={notCard} alt="" />
                                {/*<i className="iconfont icon-guanbi"></i>*/}
                            </aside>
                            <p className={styles.redP}>
                                <img src={errorImg} alt=""/>
                                识别失败
                            </p>
                        </div>
                        <div className={styles.bottom_btn}>
                            <button onClick={ handleAgain }>重试</button>
                        </div>
                    </div>

                )
            }
	    </div>
    );
};

export default ScanIdCard;