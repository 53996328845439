import {Component} from "react";
import styles from './interviewResult.moudule.less'
import {AiApi} from "../../services/aiApi";
import avatarImg from '../../assets/img/defalut_ava.png'
import markImg from '../../assets/img/mark.png'
let aiApi = new AiApi();
class InterviewResult extends Component{
    constructor(props) {
        super(props);
        this.state={
            detail:{
                result:{}
            }
        }
    }
    componentDidMount() {
        this.getDetail()
    }

    getDetail=()=>{
        let param={
            key:this.props.keyId
        }
        aiApi.get_aiInterview_result(param).then(res=>{
            console.log(res)
            if (res.data.code==200){
                this.detail=res.data.data
            }else {
                // this.$message.error(res.data.msg)
            }
        })
    }
    render() {
        return(
            <div className={styles.interviewResult}>
                <div className={styles.top}>
                    <aside>
                        <div className={styles.avatar}>
                            {
                                this.state.detail.avatar?(
                                    <img src={this.state.detail.avatar} alt=""/>
                                ):(
                                    <img src={avatarImg} alt=""/>
                                )
                            }
                        </div>
                        <div className={styles.inform}>
                            <h1>{this.state.detail.name}</h1>
                            <p>
                                {this.state.detail.sexText ? this.state.detail.sexText : ''}
                                {this.state.detail.age ? '| ' + this.state.detail.age + '岁' : ''}
                                {this.state.detail.resumeWorkingLife ? '| ' + this.state.detail.resumeWorkingLife + '年工作经验' : ''}
                            </p>
                        </div>
                    </aside>
                    <div className={styles.mark}>
                        <section>
                            <img src={markImg} alt=""/>
                            <p>{this.state.detail.result.total_points}</p>
                        </section>
                    </div>
                </div>
                <div className={styles.comment}>
                    <h5>面试评语</h5>
                    <p>{this.state.detail.result.appraise}</p>
                </div>
                <div className={styles.grade}>
                    <div className={styles.remark}>
                        <button className={styles.btn}>简历得分</button>
                        <p>
                            <span>{this.state.detail.result.match_degree_score_for_CV_and_position}</span>
                            /100分
                        </p>
                    </div>
                    <div className={styles.txt}>
                        <p>{this.state.detail.result.match_degree_comment_for_CV_and_position}</p>
                    </div>
                </div>
                <div className={styles.grade}>
                    <div className={styles.remark}>
                        <button className={styles.btn}>专业技能</button>
                        <p>
                            <span>{this.state.detail.result.score_of_professional_knowledge}</span>
                            /100分
                        </p>
                    </div>
                    <div className={styles.txt}>
                        <p>{this.state.detail.result.evaluation_of_professional_knowledge}</p>
                    </div>
                </div>
                <div className={styles.grade}>
                    <div className={styles.remark}>
                        <button className={styles.btn}>解难本领</button>
                        <p>
                            <span>{this.state.detail.result.problem_solving_ability_score}</span>
                            /100分
                        </p>
                    </div>
                    <div className={styles.txt}>
                        <p>{this.state.detail.result.problem_solving_ability_comment}</p>
                    </div>
                </div>
                <div className={styles.grade}>
                    <div className={styles.remark}>
                        <button className={styles.btn}>学习能力</button>
                        <p>
                            <span>{this.state.detail.result.the_ability_score_of_learning}</span>
                            /100分
                        </p>
                    </div>
                    <div className={styles.txt}>
                        <p>{this.state.detail.result.evaluation_of_learning_ability}</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default InterviewResult