import React, {Component} from 'react';
import { withRouter } from './WithRouter';
import homeStyle from './smallHomeV2.module.less'
import Loading from "../components/loading/loading";
import SpeakLoading from "../components/speakLoading/SpeakLoading";
import audioIcon from "../assets/img/smallProgram/icon.png";
import audioIcon1 from "../assets/img/smallProgram/icon1.png";
import bag from "../assets/img/smallProgram/bag.png";
import policy from "../assets/img/smallProgram/policy.png";
import record from "../assets/img/smallProgram/record.png";
import resumeImg from "../assets/smallProgram/resumeImg.png";
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {AudioApi} from '../services/audioApi.js';
import {ChatApi} from "../services/chatApi.js";
import signPop from "../components/flow/signPop/signPop";
import axios from 'axios';
import {DeviceApi} from "../services/deviceApi";


const audioApi = new AudioApi();
const chatApi = new ChatApi();
const deviceApi = new DeviceApi();

class SmallHomeV2 extends Component {

    chatConRef = React.createRef();
    btnRef = React.createRef();
    chooseChatRef = React.createRef();

    constructor(props) {
        super();
        this.state = {
            webGlId: null,
            difyKey: null,
            chatList: [],
            content: "",
            strIndex: null,
            strTxt: '',
            chooseChatShow: true,
            audioFlag: false,
            showAnyWay: false,
            showSpeakExample: false,
            chat_id: "",
            showLoading: true,
            displayName: ""
        }

        this.overTime = null;
        this.recodTimer = null;
        this.timeJudge = null;
        this.judgeNum = 0;

        this.audioContext = null
        this.mediaRecorder  = null;
        this.chunks = []
        this.constraints = {
            audio: {
                sampleRate: 16000, // 设置采样率为 48kHz
            }
        };


        this.ws = null;
        this.websocketTimer = null;
        // let recorder = null;

        this.chat_index = 0;
        this.chat_temp_index = 0;
        this.chat_text_temp_index = 0;
        this.chatRepId = "";  //用于做打断标记 ，stop就是打断
        this.timer = null;

        this.queue = []
    }

    componentDidMount(){

        const query = new URLSearchParams(this.props.router.location.search);
        const indCode = query.get('indCode');
        console.log(indCode); // 打印查询参数


        if(indCode){
            deviceApi.getSmallProgramWebglByIndCode(indCode).then(res => {
                if(res.data.code === 200){

                    let data =res.data.data;
                    console.log(data)

                    this.setState({
                        webGlId: data.id,
                        difyKey: data.applicationId,
                        displayName: data.displayName
                    })

                    var syncData = {
                        webGlId: data.id,
                        difyKey: data.applicationId,
                        displayName: data.displayName
                    }

                    /* global my */
                    if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                        my.postMessage({type:"sceneReady",syncData});
                    }

                }else{
                    console.log("加载设备信息出错，请联系官方客服")
                }
            })
        }else{
            this.setState({
                webGlId: 16,
                difyKey: "app-4EyI4VBSPQR1B6xYDqoL8y6U"
            })
        }



        //监听小程序回传的语音
        window.addEventListener('mAudio', this.handleAudio);
        window.addEventListener('SceneReady', this.closeLoading);

        this.connectWsExc(() => {
            setTimeout(() => {
                this.heartCheck()
            },2000)
        })

    }

    componentWillUnmount() {
        if(this.ws){
            this.ws.close();
        }

        if(this.websocketTimer){
            clearInterval(this.websocketTimer)
            this.websocketTimer = null;
        }

        this.clearTimer();
    }

    isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    isAndroid = () => {
        return /Android/.test(navigator.userAgent);
    };

    showChat = () => {
        let num=this.btnRef.current.offsetHeight
        let h=null
        if(this.chooseChatRef.current){
            let chat=this.chooseChatRef.current.offsetHeight
            h=num-chat+10
            // h=num+10
        }else{
            h=num+10
        }
        this.chatConRef.current.style.maxHeight="calc(100vh - "+h+"px)"
    }

    heartCheck = () => {
        this.websocketTimer = setInterval(() => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws.send("ping");
            }else{
                this.connectWsExc();
            }


        }, 3000);
    }

    getChatId = () => {

        if(!this.state.chat_id){
            let newChatId = uuidv4();
            this.setState({
                chat_id: newChatId
            })

            return newChatId
        }

        return this.state.chat_id;
    }

    handleAudio = (res) => {

        if(res.detail.data){
            this.sendWsMessage(res.detail.data)
        }
    }

    closeLoading= (res) => {
        this.setState({
            showLoading: false
        },() => {
            this.showChat()
        })
    }

    sendWsMessage = (base64Str) => {
        console.log('Base64 String:', base64Str);
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            console.log('send to ws');
            this.ws.send("{mp3}" + base64Str);
        }
    }

    getWsUrl = () => {
        let chat_id = this.getChatId();
        var baseUrl ='wss://digapitest.sangucloud.com/asr/'+ chat_id;
        return baseUrl
    }


    // refreshAsrCloseTime = () => {
    //     this.closeAsrCloseListener();
    //     this.createAsrCloseListener();
    //
    // }
    // }

    // 创建asr提交录音文本监听， 如果2000毫秒未收到 asr文本，则提交
    createAsrSendListener = () => {
        if(this.asrSendTimer === null){

            this.asrSendTimer = setTimeout(() => {
                this.sendAsrText();
                this.closeAsrSendListener();

            }, 1500);
        }
    }
    refreshAsrSendTime = () => {
        this.closeAsrSendListener()
        this.createAsrSendListener()
    }
    closeAsrSendListener = () => {
        if(this.asrSendTimer != null){
            clearTimeout(this.asrSendTimer);
            this.asrSendTimer = null;
        }
    }

    connectWsExc = (callback) => {
        let websocket=new WebSocket(this.getWsUrl())
        websocket.onopen = () => {
            console.log('open websocket');

            if(callback){
                callback();
            }
        };

        let that=this
        websocket.onmessage= (res)=> {
            // clearTimeout(this.overTime);

            // this.refreshAsrSendTime();

            // this.judgeNum = 0;
            this.chatRepId = "";

            let newChatList = this.state.chatList;

            if(newChatList.length > 0){
                let lastItem = newChatList[newChatList.length -1];

                if(lastItem.user !== "user" ){
                    //添加新的对话
                    let item = {
                        "reqId": "",
                        "content": '',
                        "user": "user",
                        "status": 0
                    }

                    newChatList.push(item)
                    this.setState({
                        chatList: newChatList
                    })
                    this.scrollToBottom()
                }
            }else{
                //添加新的对话
                let item = {
                    "reqId": "",
                    "content": '',
                    "user": "user",
                    "status": 0
                }

                newChatList.push(item)
                this.setState({
                    chatList: newChatList
                })
                this.scrollToBottom()
            }

            let obj=JSON.parse(res.data)
            console.log('接收到服务器返回的消息：' + res.data);
            // alert(res.data);
            if(this.state.strIndex){
                console.log('num',this.state.strIndex,obj.index);
                let str=''
                if(this.state.strIndex === obj.index){
                    str=obj.text
                    this.setState({
                        strTxt: str
                    })
                }else{
                    let newContent =this.state.content + this.state.strTxt
                    this.setState({
                        content: newContent,
                        strTxt: obj.text,
                        strIndex: obj.index
                    })
                }
            }else{

                this.setState({
                    strTxt: obj.text,
                    strIndex: obj.index
                })
            }
            //edmund 临时

            // if(obj.last && obj.last ===1){
            //     alert("fdsfsd");
            //     this.sendAsrText();
            // }

            console.log(this.state.content);
        }

        websocket.onclose =()=>{
            console.log('关闭ws');
        }

        // setWs(websocket)
        this.ws = websocket;
        console.log(this.ws)
    }

    judgeStop = () => {
        console.log(this.timeJudge);
        if (this.timeJudge != null) {
            clearTimeout(this.timeJudge);
        }
        // clearTimeout(this.overTime);
        this.timeJudge=setTimeout(()=>{
            console.log('一秒内没有再次接收到数据');
            console.log(this.content);
            this.timeJudge = null;
            // this.recStop()
            let tempContent = "";
            if(this.state.strTxt){
                tempContent =this.state.content+this.state.strTxt

                this.setState({
                    content: tempContent,
                    strTxt: ''
                },() => {

                })
            }

            if(tempContent){
                this.ask(tempContent)
            }else{

                let tempChatList = this.state.chatList;
                tempChatList.pop();

                this.setState({
                    beginReturn: false,
                    chatList: tempChatList
                })

            }
            console.log(this.state.content);
            // this.ask()
        },1000)
    }

    sendAsrText = () => {
        let tempContent = "";
        if(this.state.strTxt){
            tempContent =this.state.content+this.state.strTxt

            this.setState({
                content: tempContent,
                strTxt: ''
            },() => {

            })
        }

        if(tempContent){
            this.ask(tempContent)
        }else{

            let tempChatList = this.state.chatList;
            tempChatList.pop();

            this.setState({
                beginReturn: false,
                chatList: tempChatList
            })

        }
        console.log(this.state.content);
    }

    toProgram = (e) =>{
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"positionSearch",e});
        }
    }

    toRecordList = () =>{
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"record"});
        }
    }


    showExample = (e) =>{

        this.stopBroadcast();

        if(e===1){
            this.toProgram("positionSearch|position=&toWork=1")
            //
            // this.ask("找下销售相关的工作")
        }else{

            let item = {
                "reqId": '',
                "content": '',
                "user": "assistants",
                "status": 1,
                'showButton':false,
                'params':''
            }

            let newChatList = this.state.chatList;
            newChatList.push(item)
            this.setState({
                chatList: newChatList
            })

            this.chat_index = 1
            this.chat_temp_index = 1
            this.chat_text_temp_index = 1;

            var data = {
                reqId: "",
                type: 'chat',
                content: "你的数智助手小顾前来报到，请问想咨询哪方便的政策，你可以直接对我说。目前我擅长社保、医保、工伤险、创业帮扶、培训等内容的咨询。",
                last: true
            }

            this.showAiContent(data,this.chat_index)
        }
    }

    scrollToBottom = () => {
        setTimeout(() => {
            try {
                if (this.chatConRef.current) {
                    this.chatConRef.current.scrollTop = this.chatConRef.current.scrollHeight;
                }
            } catch (e) {
                console.log(e);
            }
        },500)

    }

    delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    clearBroadcast = () => {
        this.queue = [];
        window.Player.current.stop();
    }

    stopBroadcast = () =>{
        // clearTimer();

        this.queue = [];
        this.chatRepId = "stop";
        window.Player.current.stop();
    }

    showAiContent = async (jsonObj, index) =>{
        this.chatRepId = "";
        console.log(jsonObj)
        this.scrollToBottom()

        this.setState({
            chooseChatShow: false
        })
        // clearTimeout(this.overTime);
        let contentStr = "";
        let params = "";
        if(jsonObj){
            contentStr = jsonObj.content;
            params = jsonObj.params
        }else{
            contentStr = "";
        }

        if (contentStr) {

            this.setState({
                stopFlag: true,
                messageSend: false,
                voiceFlag: true
            })

            let reqId = jsonObj.reqId ? jsonObj.reqId : "";
            let lastItem = null
            let newChatList = this.state.chatList;
            if(newChatList[newChatList.length - 1].content){
                lastItem=newChatList[newChatList.length - 1];
            }else{
                lastItem=newChatList[newChatList.length - 2];
            }
            if (lastItem && lastItem.reqId !== '' && lastItem.reqId === reqId) {
                let textArr = contentStr.split('');
                for (let i = 0; i < textArr.length; i++) {
                    lastItem.content += textArr[i];

                    this.setState({
                        chatList: newChatList
                    })
                    await this.delay(150);
                    this.scrollToBottom()

                }
            } else {
                console.log(contentStr,'con')
                if (params){
                    // this.openShow(params)

                }

                let item = {
                    "reqId": reqId,
                    "content": contentStr,
                    "user": "assistants",
                    "status": 1,
                    'showButton': params ? true : false,
                    'params':params
                }

                newChatList.pop()
                newChatList.push(item)
                this.setState({
                    chatList: newChatList
                })
                this.scrollToBottom()

                // for (let i = 0; i < textArr.length; i++) {
                //     if (i === 0) {
                //         let item = {
                //             "reqId": reqId,
                //             "content": textArr[i],
                //             "user": "assistants",
                //             "status": 1,
                //             'showButton': params ? true : false,
                //             'params':params
                //         }
                //
                //         newChatList.pop()
                //         newChatList.push(item)
                //         setChatList(newChatList)
                //         scrollToBottom();
                //
                //     } else {
                //         let lastItem = newChatList[newChatList.length - 1];
                //         lastItem.content += textArr[i];
                //         setChatList(newChatList)
                //         scrollToBottom();
                //     }
                //     await delay(150);
                // }
            }


            await chatApi.getChatData(contentStr).then(res => {

                // alert(JSON.stringify(res))

                if(this.chatRepId !== "stop"){
                    if(res.data && res.data.voiceBroadcastResult){
                        let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)

                        let data = {
                            index: index,
                            voiceData: voiceData,
                            last: jsonObj.last,
                            chatObj: jsonObj,
                            rep_id: jsonObj.reqId,
                            openAudio: true
                        }
                        this.queue.push(data)
                        // this.props.addChatQueue(data)

                        this.createTimer();
                    }else{
                        // alert("未")
                    }
                }
            })

        }
    }

    createTimer = () => {
        if(this.timer == null){
            this.timer = setInterval(() => {
                this.execQueue();
            },100)
        }
    }

    clearTimer = () => {
        clearInterval(this.timer);
        this.timer = null;
    }

    execQueue = () => {
        if(this.queue && this.queue.length > 0){

            let data = this.findAndCopy(this.queue, this.chat_temp_index)
            // console.log("------------------execQueue------------------------");
            // console.log(data);
            if(data !== null) {

                // alert(window.Player.current.playing)
                // alert(window.tempPlayStatus)

                if (!window.Player.current.playing && window.tempPlayStatus === 0) {
                    if (data.voiceData) {
                        window.tempPlayStatus = 1;

                        window.Player.current.setData(data.voiceData);
                    }
                    this.chat_temp_index ++
                    if (data.last) {
                        this.queue = [];
                    }
                }
            }
        }
        else{

        }
    }


    findAndCopy = (list,index) => {

        if(list && list.length > 0){
            for(var i=0 ;i < list.length;i++){
                let data = list[i];

                if(data.index === index){
                    return this.copy(data);
                }
            }
        }

        return null;
    }

    copy = (data) => {
        let copystr = JSON.stringify(data);
        let copy = JSON.parse(copystr);
        return copy;
    }

    startToRecord = () =>{

        // this.judgeNum = 0;

        // console.log("开始录音")
        // setVoiceFlag(true)
        // setBeginReturn(false)
        // setAudioFlag(true)
        //
        // let num=0
        // //实时
        // connectWsExc(() => {
        //     setTimeout(()=>{
        //         initAudio()
        //     },500)
        //
        //     recodTimer=setInterval(()=>{
        //         if(num===60){
        //             clearInterval(recodTimer)
        //             recStop()
        //         }else{
        //             num++
        //         }
        //     },1000)
        // })

        this.setState({
            content: '',
            strTxt: ''
        },() => {
            this.initAudio()
        })


        // startRecord();
        // recStart();

    }

    initAudio = () =>{
        this.judgeNum = 0;

        // setTimeout(()=>{
        this.stopBroadcast();
        this.startRecord()
        // },100)
    }

    //结束
    endRecord = () =>{
        // if(audioFlag){
        //     recStop()
        // }
        console.log("录音结束");
        // recStop()
        setTimeout(() => {
            this.stopRecording();
            this.sendAsrText();
        }, 1500);

        // this.createAsrSendListener();
        // setTimeout(() => {
        //
        //     this.sendAsrText();
        //
        // }, 1500);



        // this.timeJudge = setInterval(() => {
        //
        //     this.judgeNum = this.judgeNum + 200;
        //     // alert(judgeNum);
        //     if(this.judgeNum >= 1600){
        //         // alert("提交用户语音");
        //         // alert(content);
        //         let newContent = ""
        //         if(this.state.strTxt){
        //             newContent=this.state.content + this.state.strTxt
        //         }
        //
        //         let newChatList = this.state.chatList;
        //         newChatList.pop();
        //
        //         this.setState({
        //             chatList: newChatList
        //         })
        //
        //         if(newContent){
        //             // alert(judgeNum);
        //             this.ask(newContent)
        //         }
        //
        //         clearInterval(this.timeJudge)
        //         this.timeJudge = null;
        //         this.judgeNum = 0;
        //
        //         this.setState({
        //             content: "",
        //             strTxt: "",
        //             strIndex: null
        //         })
        //     }
        //
        // },200)
    }

    startRecord = () => {

        // console.log("startRecord------------------------------");
        // console.log(recorder);
        // if (recorder) {
        //     recorder.start()
        //     setIsRecording(true);
        // }

        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("打开小程序语音")
            my.postMessage({type:  "mAudio", act: "start"});
            this.setState({
                audioFlag: true
            })
        }

    }

    stopRecording = () => {
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("打开小程序语音")
            my.postMessage({type:  "mAudio", act: "close"});
        }
    };

    blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result.split(',')[1];
                resolve(base64Data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    testDify = () => {
        // this.aiTalk("帮我找下销售相关的职位")
        this.ask("帮我找下销售相关的职位")
    }

    ask = (message) => {
        // if (content !== "") {
        //     add();
        // }
        // let message = "";
        // for (let i = 0; i < chatList.length; i++) {
        //     let item = chatList[i];
        //     if (item.status === 0) {
        //         message += chatList[i].content + "\n";
        //         item.status = 1;
        //     }
        // }
        if (message !== "") {
            //       message = "{indCode="+ this.indCode +"}"+"{weglId="+ this.webGlId +"}" + message;
            // if(this.poisitinId){
            // 	message="{position_id="+ this.poisitinId +"}"+message
            // }
            console.log("ask message----------------------")
            console.log(message)

            this.setState({
                messageSend: true,
                voiceFlag: false,
                audioFlag: false
            })


            let userItem = {
                "reqId": "",
                "content": message,
                "user": "user",
                "status": 0
            }

            let item = {
                "reqId": '',
                "content": '',
                "user": "assistants",
                "status": 1,
                'showButton':false,
                'params':''
            }
            let newChatList = this.state.chatList
            newChatList.pop();
            newChatList.push(userItem)
            newChatList.push(item)

            this.setState({
                chatList: newChatList,
                chooseChatShow: false,
                content: "",
                strTxt: ""
            },() => {
                console.log(this.state.chatList)
                //TODO: 这里需要调dify接口
                this.aiTalk(message)
            })


        }
    }

    aiTalk = async (message) => {

        // await this.blockChat(message);

        this.chatRepId = "";
        if(this.isIOS()){
            await this.blockChat(message);
        }else if(this.isAndroid()){
            await this.streamChat(message);
        }else{
            await this.streamChat(message)
        }
    }

    blockChat = async (message) => {
        let chat_id = this.getChatId();
        chatApi.blockChat(chat_id, message,this.state.difyKey).then(res => {

            this.chat_index = 1
            this.chat_temp_index = 1
            this.chat_text_temp_index = 1;

            if(res.data.code === 200){

                let data = res.data.data;
                let json = "";
                if(res.data.params){
                    json = {
                        reqId: "",
                        content: data,
                        last: true,
                        type: 'chat',
                        params: res.data.params
                    }
                }else{
                    json = {
                        reqId: "",
                        content: data,
                        last: true,
                        type: 'chat',
                        params: ""
                    }
                }

                this.showAiContent(json,this.chat_index)

            }else{
                let json = {
                    reqId: "",
                    content: "我无法回答您的问题",
                    last: true,
                    type: 'chat',
                    params: ""
                }
                this.showAiContent(json,this.chat_index)
            }
        });


    }

    streamChat = async (message) => {

        let chat_id = this.getChatId();

        console.log("-----------------difyKey--------------------");
        console.log(this.state.difyKey);
        // alert(this.state.difyKey);

        console.log("-----------------webGlId--------------------");
        console.log(this.state.webGlId);

        const res = await fetch('/digApi/digital_dify/chat_word_v2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            dataType: "text/event-stream",
            body: JSON.stringify({
                "chat_id": chat_id,
                "user_id": "xiaogu",
                "ind_code": '',
                "position_id":  "",
                "position_type": "",
                "webgl_id": this.state.webGlId,
                "key": this.state.difyKey,
                "content": message
            })
        });

        this.chat_index = 0
        this.chat_temp_index = 1
        this.chat_text_temp_index = 1;

        if(!res.ok){
            throw new Error(`HTTP error! status: ${res.status}`);

            let data = {
                index: this.chat_index,
                voiceData: "",
                last: true,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            this.queue.push(data)
        }

        const reader = res.body.getReader();
        let decoder = new TextDecoder();
        let result = true;

        while (result) {
            const { done, value } = await reader.read();
            // console.log("--------------done---------------:{}",done);
            result = !done;

            if(this.chatRepId === "stop"){
                result = false
                continue;
            }

            if(done){
                this.chat_index ++;
                // window.tempPlayStatus = 1
                let data = {
                    index: this.chat_index,
                    voiceData: "",
                    last: true,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                this.queue.push(data)
            }

            // try {
            let text = decoder.decode(value);
            console.log("------------dify 返回内容------------")
            console.log(text)
            // alert(text);
            if(text){ //现在如果dify直接返回空则是 无法回答， 之后应该是固定的文案

                const formattedData = text.replace(/\n/g, '');
                const dataEntries = text.trim().split('data: ').slice(1);

                for(var i=0; i < dataEntries.length; i++){
                    let entry = dataEntries[i];
                    try{
                        let json = JSON.parse(entry);
                        // console.log(json)
                        json.last = false;
                        this.chat_index ++;
                        await this.showAiContent(json,this.chat_index)

                    }catch (e){
                        console.log(e)
                    }
                }
            }

        }

    }

    //查看其他
    anyMore = () =>{
        if(this.state.showAnyWay||this.state.showSpeakExample){
            //全收起来
            this.setState({
                showAnyWay: false,
                showSpeakExample: false
            })
        }else{
            //展示三个按钮
            this.setState({
                showAnyWay: true
            })
        }
        setTimeout(()=>{
            this.showChat()
        },300)
    }
    toResume=()=>{
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"resume"});
        }
    }

    render() {
        return this.state.showLoading ? (
                <Loading></Loading>
            ):(
            <div className={homeStyle.homeIndex}>
                <div className={homeStyle.home}>
                    <div className={homeStyle.dig_con}>
                        <div className={homeStyle.textCon}>
                            <div className={homeStyle.chatCon}>
                                <div className={homeStyle.chatItem} ref={this.chatConRef}>
                                    <div className={homeStyle.itemCon}>
                                        {
                                            this.state.chatList.map((item, index) => (

                                                item.user === 'user'? (
                                                    <div key={index} className={homeStyle.userCon} >
                                                        <p className={`${homeStyle.bgBlue} ${homeStyle.robotItem}`} >
                                                            {
                                                                item.content||this.state.content||this.state.strTxt?(
                                                                    <>
                                                                        {item.content ? item.content : this.state.content + this.state.strTxt}
                                                                    </>
                                                                ):('')
                                                            }
                                                        </p>
                                                    </div>
                                                ):(
                                                    <div key={index} className={homeStyle.robotCon}>
                                                        {
                                                            item.content ? (
                                                                <div className={homeStyle.robotItem}>
                                                                    {item.content}
                                                                    {
                                                                        item.showButton ? (
                                                                            <button onClick={() => {
                                                                                this.toProgram(item.params)
                                                                            }}>查看职位</button>
                                                                        ) : ('')
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className={homeStyle.robotItem}>
                                                                    <SpeakLoading></SpeakLoading>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={homeStyle.btn} ref={this.btnRef}>
                                {
                                    this.state.chooseChatShow ? (
                                        <div className={homeStyle.chooseChat} ref={this.chooseChatRef}>
                                            <p>您好，我是您的求职助手小顾，您有什么要求可以直接对我说。</p>
                                            <button className={homeStyle.itemButton} onClick={() => this.showExample(1)} >我想找工作</button>
                                            {/*<button className={homeStyle.itemButton} onClick={() => this.testDify()} >我想找工作</button>*/}
                                            <button className={homeStyle.itemButton} onClick={() => this.showExample(2)}>查询政策</button>
                                            <button className={homeStyle.itemButton} onClick={this.toRecordList}>查看报名记录</button>
                                        </div>
                                    ) : ('')
                                }

                                <div className={homeStyle.speakBtn}>
                                    <button  onTouchStart={this.startToRecord} onTouchEnd={this.endRecord}>
                                        {
                                            this.state.audioFlag ? (
                                                <img src={audioIcon} alt="" />

                                            ):(
                                                <img src={audioIcon1} alt="" />
                                            )
                                        }
                                        {
                                            this.state.audioFlag ? ('正在录入') : ('按住说话')
                                        }
                                    </button>
                                    <aside onClick={this.anyMore}>
                                        <i className={this.state.showAnyWay || this.state.showSpeakExample?'iconfont icon-guanbi':'iconfont icon-jia'}></i>
                                    </aside>
                                </div>
                                {
                                    this.state.showAnyWay?(
                                        <div className={homeStyle.checkBtn} id="checkBtn">
                                            <div className={`${homeStyle.btnItem} ${homeStyle.btnOne}`} onClick={() => this.showExample(1)}>
                                                <img src={bag} alt="" />
                                                <span>找工作</span>
                                            </div>
                                            <div className={`${homeStyle.btnItem} ${homeStyle.btnTwo}`} onClick={() => this.showExample(2)}>
                                                <img src={policy} alt="" />
                                                <span>查政策</span>
                                            </div>
                                            <div className={`${homeStyle.btnItem} ${homeStyle.btnThree}`} onClick={this.toRecordList}>
                                                <img src={record} alt="" />
                                                <span>看记录</span>
                                            </div>
                                            <div className={`${homeStyle.btnItem} ${homeStyle.btnFour}`} onClick={this.toResume}>
                                                <img src={resumeImg} alt="" />
                                                <span>看简历</span>
                                            </div>
                                        </div>
                                    ):('')
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div style={{position:'absolute',top:"10%",width:'200px',left:'5%',zIndex:"1000"}}>*/}
                    {/*    <input style={{height:"40px",width:'150px'}} placeholder="请输入文本" value={this.state.strTxt}  onChange={(event) => this.setState({ strTxt: event.target.value })}/>*/}
                    {/*    <button onClick={this.sendAsrText}>add</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default withRouter(SmallHomeV2);