// 企业直招
import React, { useState, useEffect, useRef } from 'react';
import style from './directAdmission.module.less'
import hotIcon from '../../assets/img/urgent.png';
import filters from '../../utils/filters.js';
import '../../assets/iconfont/iconfont.css'
import defaultIcon from '../../assets/img/defaultIcon.png'
import {PositionApi} from "../../services/positionApi.js";
import PageLoading from '../speakLoading/pageLoading'

import { useDispatch } from 'react-redux';
import {setTimerType, clearTimerType} from '../../store/actions'
import { CSSTransition } from 'react-transition-group';
import '../test/test.css';

import AMapLoader from "@amap/amap-jsapi-loader";

const DirectAdmission = ({poisitinInfoData,toAsk}) => {
    let positionAi = new PositionApi();
    const positionId = poisitinInfoData.positionId;
    const [details, setDetails] = useState({});
    const headInfoRef = useRef(null);
    const [elementHeight, setElementHeight] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showCon, setShow] = useState(false);

    const dispatch = useDispatch();

    let map = null;

    let settleTypeDateList=[    //结算方式
            {value:0, label: '日结'},
            {value:1, label: '周结'},
            {value:2, label: '月结'},
    ]

    useEffect(() => {
        dispatch(setTimerType("positionInfo"))

        return () => {
            dispatch(clearTimerType())

            if(map!==null){
                map.on("complete", () => {
                    map.destroy();
                    map=null
                })

            }
        }
    }, []);

    useEffect(() => {
        getPositionInfo();
    }, [positionId]);

    useEffect(() => {
        const element = document.getElementById('headInfoId');
        // if (element) {
        //     const height = element.clientHeight;
        //     const elHeight = 'calc(100% - ' + (height + 20) + 'px)';
        //     setElementHeight(elHeight);
        // }
    },[details])


    const getPositionInfo = () => {
        setLoading(true);
        let params = {
            positionId: positionId
        }
        positionAi.getDirectDetail(params).then((res) => {
            if(res.data.data) {
                let obj=res.data.data
                // getMap(obj)
                if(obj.positionDescription){
                    let str=''
                    str=obj.positionDescription
                    let arr=str.split('\n')
                    obj.positionDescriptionArr=[]
                    arr.map(el=>{
                        if(el!==''){
                            obj.positionDescriptionArr.push(el)
                        }
                    })
                }
                if(obj.bonusDetail){
                    let str=''
                    str=obj.bonusDetail

                    let arr=str.split('\n')
                    obj.bonusDetailArr=[]
                    arr.map(el=>{
                        if(el!==''){
                            obj.bonusDetailArr.push(el)
                        }
                    })
                }
                let date=''
                settleTypeDateList.map(el=>{
                    if(el.value==obj.settleType){
                        obj.settleType=el.label
                        if(el.value!==2){
                            date=el.label.charAt(0)
                            obj.settleTimeCycle=obj.settleTimeCycle+date

                        }else{
                            obj.settleTimeCycle='每月结算'
                        }
                    }
                })
                if (obj.orgInfoDto && obj.orgInfoDto.ceLogo) {
                    obj.orgInfoDto.ceLogo= obj.orgInfoDto.ceLogo+'?x-oss-process=image/resize,m_fill,w_60,quality,q_60'
                }

                setDetails(obj);
            }
            setLoading(false);
            setTimeout(()=>{
                setShow(!showCon)
            },500)
            console.log(showCon,'dsfhjdshfj',loading)
        })
    }
    const getMap=(e)=> {
        if(e.orgInfoDto.longitude>0&&e.orgInfoDto.latitude>0){
            AMapLoader.load({
                key: "0ac44ea4c0cf20b0b926fa850a9ee9be", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ["AMap.Scale"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
            })
                .then((AMap) => {
                    setTimeout(()=>{
                        setTimeout(()=>{
                            console.log(document.getElementById('allmap'))
                            if(document.getElementById('allmap')){
                                map = new AMap.Map("allmap", {
                                    // 设置地图容器id
                                    viewMode: "2D", // 是否为3D地图模式
                                    zoom: 15, // 初始化地图级别
                                    center: [e.orgInfoDto.longitude,e.orgInfoDto.latitude], // 初始化地图中心点位置
                                });
                                var marker = new AMap.Marker({
                                    position: new AMap.LngLat(e.orgInfoDto.longitude,e.orgInfoDto.latitude),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                                });
                                map.add(marker);
                            }
                        },200)

                    },500)

                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }
    
    function refreshTimer() {}
    const toAskQuestion=(e)=>{
        let arr=['这个岗位的工资是多少，有没有加班费','每天工作多长时间，周末能休息吗','公司提供住宿吗，住宿条件怎么样','具体需要做些什么工作，这个岗位需要什么技能']
        let str=arr[e]
        toAsk(str)
    }
    return (
        <div className={style.positionWrap}>
            {
                loading ? (
                    <div className={style.loading}>
                        <PageLoading></PageLoading>
                    </div>
                ) : (
                    <div className={style.detailHeight}>
                        <CSSTransition in={showCon} timeout={300} classNames={{
                            enter: "alert-enter",
                            enterActive: "alert-enter-active",
                            exit: "alert-exit",
                            exitActive: "alert-exit-active",
                        }} unmountOnExit>
                            <div className={style.detail}>
                                <aside ref={headInfoRef} id='headInfoId'>
                                    <div className={style.header}>
                                        <div className={style.conTitle_left}>
                                            <h1>{details.positionName}</h1>
                                            {details.isUrgent === 1 && <img src={hotIcon} className={style.urgent} alt=""/>}
                                        </div>
                                        <div className={style.conTitle_right}>
                            <span>
                                {details.positionType === 0 && details.minSalary && details.maxSalary &&
                                `${details.minSalary}-${details.maxSalary}元•${details.salaryMonths?details.salaryMonths:12}薪`}
                            </span>
                                            <span>
                                {details.positionType === 0 && details.discussPersonally === 1 &&
                                '面议'}
                            </span>
                                            <span>
                                {details.positionType !== 0 && details.partTimeSalary && `${details.partTimeSalary} ${filters.getSalaryType(details.partTimeSalaryType)}`}
                            </span>
                                        </div>
                                    </div>
                                    <section>
                                        {details.subsidyLabel && details.subsidyLabel.map((item, index) => (
                                            item !== '不限' && <p key={index}><span>{item}</span></p>
                                        ))}
                                    </section>
                                    <div className={style.content}>
                                        <div className={style.str}>
                                            <b className={style.strCompany}>
                                                <i className="iconfont icon-gongzuojingyan"></i>
                                                {details.orgInfoDto && details.orgInfoDto.ceName}
                                            </b>
                                            <b>| 招{details.employeeNum}人</b>
                                            <b>| {filters.getPotitionType1(details.positionType)}</b>
                                            <b>| {details.digEducationStr ? details.digEducationStr : '学历不限'}</b>
                                            <b style={{
                                                marginLeft: '3px',
                                                marginRight: '3px'
                                            }}>| {filters.getWorkingLife(details.workingLife)}</b>
                                        </div>
                                        <div className={style.str}>
                                            <i className="iconfont icon-adress"></i>
                                            <b>{details.digWorkPlace}</b>
                                        </div>
                                    </div>
                                </aside>
                                <div className={style.question}>
                                    <h1>您可以问我以下问题：</h1>
                                    <button onClick={()=>toAskQuestion(0)}>岗位的工资是多少</button>
                                    <button onClick={()=>toAskQuestion(1)}>工作时间是怎样的</button>
                                    <button onClick={()=>toAskQuestion(2)}>公司提供住宿吗</button>
                                    <button onClick={()=>toAskQuestion(3)}>工作有什么要求？</button>
                                </div>
                                <div className={style.conHeight} style={{height: elementHeight ? elementHeight : ''}}>

                                    <div className={style.sectionCon}>
                                        <article>
                                            <h4>职位详情</h4>
                                            <div className={style.homeDetail}>
                                                <div className={style.item}>
                                                    <div className={style.itemRight}>
                                                        {details.positionDescriptionArr && details.positionDescriptionArr.map((item, key) => (
                                                            <p key={key} style={{textAlign: 'justify'}}>{item}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* <div className={style.more} onClick={() => getMore(1)} style={{ display: positionDetailFlag ? 'block' : 'none' }}>
                                    <more></more>
                                </div> */}
                                            </div>
                                        </article>
                                        <article>
                                            <h4>薪资详情</h4>
                                            <div style={{overflow: 'hidden'}}>
                                                <div className={style.homeDetail}
                                                     style={{display: details.positionType === 0 ? 'block' : 'none'}}>
                                                    <div className={style.item}>
                                                        <div className={style.itemLeft}>薪资范围：</div>
                                                        <div className={style.itemRight}>
                                                            {details.minSalary && details.maxSalary ? `${details.minSalary}-${details.maxSalary}元 * ${details.salaryMonths?details.salaryMonths:12}薪`:''}
                                                            {details.discussPersonally === 1 ? '面议':''}
                                                        </div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.basicSalary ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>底&emsp;&emsp;薪：</div>
                                                        <div className={style.itemRight}>{details.basicSalary}元/月</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.maxBasicSalary && details.minBasicSalary ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>底&emsp;&emsp;薪：</div>
                                                        <div
                                                            className={style.itemRight}>{details.minBasicSalary}-{details.maxBasicSalary}元/月
                                                        </div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.payDay ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>发&ensp;薪&ensp;日：</div>
                                                        <div className={style.itemRight}>{details.payDay} 号</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.socialSecurityType ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>社保类型：</div>
                                                        <div className={style.itemRight}>{details.socialSecurityStr}</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.salaryWelfare ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>薪酬福利：</div>
                                                        <div className={style.itemRight}>{details.salaryWelfare}</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.royaltyType ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>提成方式：</div>
                                                        <div className={style.itemRight}>{details.royaltyStr}</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.royaltyDetail ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>提成明细：</div>
                                                        <div className={style.itemRight}>{details.royaltyDetail}</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.bonusStr ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>奖金补贴：</div>
                                                        <div className={style.itemRight}>{details.bonusStr}</div>
                                                    </div>
                                                    <div className={style.item}
                                                         style={{display: details.bonusDetail ? 'block' : 'none'}}>
                                                        <div className={style.itemLeft}>奖金明细：</div>
                                                        <div className={style.itemRight}>
                                                            {details.bonusDetailArr && details.bonusDetailArr.map((item, key) => (
                                                                <p key={key}>{item}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={style.homeDetail}
                                                     style={{display: details.positionType !== 0 ? 'block' : 'none'}}>
                                                    <div className={style.item}>
                                                        <div className={style.itemLeft}>薪&emsp;&emsp;资：</div>
                                                        <div className={style.itemRight}>
                                                            {details.partTimeSalary}
                                                            <b style={{display: 'inline-block'}}>{details.partTimeSalaryType === 0 ? '元/小时':''}</b>
                                                            <b style={{display: 'inline-block'}}>{details.partTimeSalaryType === 1 ? '元/天':''}</b>
                                                            <b style={{display: 'inline-block'}}>{details.partTimeSalaryType === 2 ? '元/一次性':''}</b>
                                                            <b style={{display: 'inline-block'}}>{details.partTimeSalaryType === 3 ? '元/月':''}</b>
                                                        </div>
                                                    </div>
                                                    <div className={style.item}>
                                                        <div className={style.itemLeft}>结算方式：</div>
                                                        <div className={style.itemRight}>{details.settleType}</div>
                                                    </div>
                                                    <div className={style.item}>
                                                        <div className={style.itemLeft}>结算时间：</div>
                                                        <div className={style.itemRight}>{details.settleTimeCycle}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className={style.more} onClick={() => getMore(2)} style={{ display: salaryFlag ? 'block' : 'none' }}>
                                <more></more>
                            </div> */}
                                        </article>
                                        <article>
                                            <h4>公司介绍</h4>
                                            <div className={style.homeDetail}>
                                                <div className={style.homeDetail_top}>
                                                    <div className={style.homeDetail_top_left}>
                                                        <img className={style.entLogo}
                                                             src={details.orgInfoDto && details.orgInfoDto.ceLogo ? details.orgInfoDto.ceLogo : defaultIcon}
                                                             alt=""/>
                                                    </div>
                                                    <div className={style.homeDetail_top_right}>
                                                        <h5>{details.orgInfoDto && details.orgInfoDto.ceName}</h5>
                                                        <p>
                                                            <span>{details.orgInfoDto && details.orgInfoDto.ceLocationStr}</span>
                                                            <span>{details.orgInfoDto && details.orgInfoDto.natureStr && ` | ${details.orgInfoDto.natureStr}`}</span>
                                                            <span>{details.orgInfoDto && details.orgInfoDto.enterpriseScaleStr && ` | ${details.orgInfoDto.enterpriseScaleStr}`}</span>
                                                            <span>{details.orgInfoDto && details.orgInfoDto.industryStr && ` | ${details.orgInfoDto.industryStr}`}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={style.homeDetail_con}>
                                                    {details.orgInfoDto && details.orgInfoDto.ceIntroduction}
                                                </div>
                                            </div>
                                            {/* <div className={style.more} onClick={() => getMore(3)} style={{ display: companyFlag ? 'block' : 'none' }}>
                                <more></more>
                            </div> */}
                                        </article>
                                        {/*{*/}
                                        {/*    details.orgInfoDto&&details.orgInfoDto.longitude>0&&details.orgInfoDto.latitude>0?(*/}
                                        {/*        <article>*/}
                                        {/*            <div id="allmap" style={{ width: "100%", height: "300px" }}></div>*/}
                                        {/*        </article>*/}
                                        {/*    ):('')*/}
                                        {/*}*/}
                                    </div>
                                    <div className={style.blockHeight}></div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                )
            }
        </div>
    );
};

export default DirectAdmission;