import React, {Component} from "react";
import verifyStyle from './continueVerify.module.less'
import KeypadEasy from 'keypad-easy'
import { Toast } from 'antd-mobile'

import { connect } from 'react-redux';
import {setTimerType, clearTimerType, setBroadKey} from '../../../store/actions'
import errorImg from '../../../assets/img/home/error.png'
import closeImg from '../../../assets/img/home/close.png'

import {SgAuthApi} from '../../../services/sgAuthApi.js';

import BtnLoading from '../../speakLoading/btnLoading'
import btnClick from "../../../assets/click1.wav";

let sgAuthApi = new SgAuthApi();
const audio = new Audio(btnClick);
class continueVerify extends Component {
    constructor(props) {
        super();
        this.state={
            itemObj:{
                tel:'',
                code:''
            },
            codeNum:null,
            txt:'发送',
            btnShow:true,
            grayColor:false,
            inputState:'',
            idCardInfoData: props.idCardInfoData,
            errBad:false,
            peopleObj:{
                name:'',
                idNum:''
            },
            loading:false
        }
    }
    componentDidMount() {
        this.props.setTimerType("continueVerify");
        this.props.setBroadKey("continueVerify");
        this.handleInform()
        KeypadEasy.onChange = (value) => {
            console.log(value)
        };
        KeypadEasy.onClick = (a) => {
            console.log(a,this.state.inputState)
            let str=''
            if (this.state.inputState==='tel'){
                str=this.state.itemObj.tel
                if (a.type=='insert'){
                    str=str+a.value
                    if (this.state.itemObj.tel&&!this.state.grayColor){
                        this.setState({
                            grayColor:true
                        })
                    }
                }else if(a.type=='delete'){
                    str=str.slice(0,-1)
                    console.log(str)
                }
                this.setState({
                    itemObj:{
                        ...this.state.itemObj,
                        tel:str
                    }
                })
            }else {
                str=this.state.itemObj.code
                if (a.type=='insert'){
                    str=str+a.value
                }else if(a.type=='delete'){
                    str=str.slice(0,-1)
                    console.log(str)
                }
                this.setState({
                    itemObj:{
                        ...this.state.itemObj,
                        code:str
                    }
                })
            }
        };
        KeypadEasy.onShow = (e) => {
            console.log('show',e)
            this.setState({
                inputState:e
            })
        };
        KeypadEasy.onHide = _ => {
            console.log('hide')
        };
        KeypadEasy.init({
            maxLength: 5
        })


        //监听小程序回传的语音
        window.addEventListener('cardCheck', this.handleIdCardCheck);
    }
    componentWillUnmount() {
        this.props.clearTimerType();
    }

    sendCode=()=>{
        this.setState({
            loading:true
        },()=>{
            this.futility()
        })
        audio.play();
        if(!this.state.itemObj.tel) {
            Toast.show({
                content: '请输入手机号',
                duration: 2000,
                maskClickable: false
            })
            this.setState({
                errBad:false,
                loading:false
            },()=>{
                this.futility()
            })
        }else {
            // const phoneRegex = /^1[34578]\d{9}$/;
            const phoneRegex =new RegExp('^1\\d{10}$', 'g');
            // 使用test方法检查手机号是否符合格式
            if (phoneRegex.test(this.state.itemObj.tel)) {
                this.setState({
                    errBad:false,
                },()=>{
                    this.futility()
                })

                sgAuthApi.sendVerfyCode(this.state.itemObj.tel).then(res => {
                    if (res.data.code==200){
                        this.setState({
                            loading:false
                        },()=>{
                            this.futility()
                        })
                        if (!this.state.codeNum){
                            this.state.codeNum=60
                            let timer=setInterval(()=>{
                                if(this.state.codeNum==0){
                                    clearInterval(timer)
                                    this.setState({
                                        codeNum:null,
                                        txt:'重新发送'
                                    })
                                }else{
                                    let i=this.state.codeNum-1
                                    this.setState({
                                        codeNum:i
                                    })
                                }
                            },1000)
                        }
                    }else {
                        this.setState({
                            loading:false
                        },()=>{
                            this.futility()
                        })
                        Toast.show({
                            content: res.data.msg,
                            duration: 2000,
                            maskClickable: false
                        })
                    }
                })
            } else {
                this.setState({
                    errBad:true,
                    loading:false
                },()=>{
                    this.futility()
                })
            }
        }
    }
    //无用的，但是不要删
    futility(){
        let i=1
    }
    // 返回
    handleBack = () => {
        this.props.onUserInfoBack(1);
    }

    // 下一步
    hendleSave = () => {
        if(!this.state.itemObj.tel) {
            Toast.show({
                content: '请输入手机号',
                duration: 2000,
                maskClickable: false
            })
            this.setState({
                errBad:true
            })
            return;
        }
        // else {
        //     // const phoneRegex = /^1[34578]\d{9}$/;
        //     const phoneRegex =new RegExp('^1\\d{10}$', 'g');
        //
        //     console.log(this.state.itemObj.tel,phoneRegex.test(this.state.itemObj.tel))
        //
        //     // 使用test方法检查手机号是否符合格式
        //     if (phoneRegex.test(this.state.itemObj.tel)) {
        //         this.setState({
        //             errBad:false
        //         },()=>{
        //             this.futility()
        //         })
        //     } else {
        //         this.setState({
        //             errBad:true
        //         })
        //         return
        //     }
        // }
        if(!this.state.itemObj.code) {
            Toast.show({
                content: '请输入验证码',
                duration: 2000,
                maskClickable: false
            })
            return;
        }



        // /* global my */
        // if (navigator.userAgent.indexOf('AlipayClient') > -1) {
        //     my.postMessage({
        //         type:"cardCheck",
        //         certName: this.state.idCardInfoData.name,
        //         certNo: this.state.idCardInfoData.idNum
        //     });
        // }

        sgAuthApi.verifyCode(this.state.itemObj.tel,this.state.itemObj.code).then(res => {
            console.log(res)
            if (res.data.code==200){
                let params = {
                    user_name: this.state.idCardInfoData.name,
                    mobile: this.state.itemObj.tel,
                    id_card: this.state.idCardInfoData.idNum,
                }
                this.props.onVerifySuccess(params);
            }else {
                Toast.show({
                    content: res.data.msg,
                    duration: 2000,
                    maskClickable: false
                })
            }
        })
    }

    handleIdCardCheck = (res) => {
        // alert(JSON.stringify(res))
        if(res.detail.data.code == 1000 || res.detail.data.code == '1000') {  // 成功
            let params = {
                user_name: this.state.idCardInfoData.name,
                mobile: this.state.itemObj.tel,
                id_card: this.state.idCardInfoData.idNum,
            }
            this.props.onVerifySuccess(params);
        }else {
            Toast.show({
                content: res.detail.data.subMessage,
                duration: 2000,
                maskClickable: false
            })
        }
    }
    clearTel(e){
        e.stopPropagation()
        console.log(999)
        this.setState({
            itemObj:{
                ...this.state.itemObj,
                tel:'',
            },
        })
    }

    //处理名字
    handleInform(){
        let name=''
        let idNum=''
        if (this.state.idCardInfoData.name){
            name=this.state.idCardInfoData.name.charAt(0)
            name=name+this.getNameStar(this.state.idCardInfoData.name)
        }
        if (this.state.idCardInfoData.idNum){
            idNum =this.state.idCardInfoData.idNum.substring(0, 6)
            idNum=idNum+'********'
            idNum=idNum+this.state.idCardInfoData.idNum.slice(-4)
        }
        this.setState({
            peopleObj:{
                name,
                idNum
            }
        })
    }
    getNameStar(e){
        let num=e.length-2
        let str=''
        for (let i=0;i<=num;i++){
            str=str+'*'
        }
        return str
    }
    openPop(){
        KeypadEasy.show('tel');
        this.setState({
            errBad:false
        })
    }
    render() {
        return (
            <div className={verifyStyle.verify}>
                {/*<h1>识别成功，继续完成验证</h1>*/}
                <h1></h1>
                <div className={verifyStyle.inform}>
                    <aside>
                        <span>姓&emsp;名</span>
                        <p>{ this.state.peopleObj.name }</p>
                    </aside>
                    <aside>
                        <span>性&emsp;别</span>
                        <p>{ this.state.idCardInfoData.sex }</p>
                    </aside>
                    <aside>
                        <span>身份证</span>
                        <p>{ this.state.peopleObj.idNum }</p>
                    </aside>
                </div>
                <div className={verifyStyle.inform}>
                    <section>
                        <p>手机号</p>
                        <article>
                            <div className={verifyStyle.informR} onClick={_ => this.openPop()}>
                                {this.state.itemObj.tel?this.state.itemObj.tel:<span>请输入手机号</span>}
                                {
                                    this.state.errBad?(
                                        <div className={verifyStyle.error}>
                                            <img src={errorImg} alt=""/>
                                            <p>格式错误</p>
                                        </div>
                                    ):('')
                                }
                                {
                                    this.state.itemObj.tel&&!this.state.errBad?(
                                        <div className={verifyStyle.error} onClick={(e)=>this.clearTel(e)}>
                                            <img src={closeImg} alt=""/>
                                        </div>
                                    ):('')
                                }
                            </div>
                        </article>
                    </section>
                    <section>
                        <p>验证码</p>
                        <article>
                            <div className={verifyStyle.informR} onClick={_ => {KeypadEasy.show('code');}}>
                                {this.state.itemObj.code?this.state.itemObj.code:<span>请输入验证码</span>}
                            </div>
                            {
                                this.state.codeNum?(
                                    <p className={verifyStyle.countDown}>已发送{this.state.codeNum}s</p>
                                ):(
                                    <button onClick={()=>this.sendCode()}>
                                        {
                                            this.state.loading?(
                                                <div className={verifyStyle.loadingBtn}><BtnLoading/></div>
                                            ):(this.state.txt)
                                        }
                                    </button>
                                )
                            }
                        </article>
                    </section>
                </div>
                {
                    this.state.btnShow?(
                        <div className={verifyStyle.btn}>
                            <aside>
                                <button onClick={ this.handleBack } className={verifyStyle.check} >取消</button>
                                <button onClick={ this.hendleSave } className={this.state.grayColor?verifyStyle.over:verifyStyle.grayCol}>下一步</button>
                            </aside>
                        </div>
                    ):('')
                }
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    setTimerType: (timerType) => {
        dispatch(setTimerType(timerType))
    },
    clearTimerType: () => dispatch(clearTimerType()),
    setBroadKey: (broadKey) => {
        dispatch(setBroadKey(broadKey))
    },
});
export default connect(null, mapDispatchToProps)(continueVerify);
// export default continueVerify;