import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"

class DeviceApi{

    async getDeviceConfig(sn){

        let data = {
            sn: sn,
        }

        var url = api + "system/web/device/getDeviceConfig"
            // "?authType=8&code="+ code +"&mobile="+mobile;
        return get(url,data);
    }

    async getSmallProgramWebglByIndCode(indCode){

        let data = {
            indCode: indCode,
        }

        var url = api + "system/web/gl/getSmallProgramWebglByIndCode"
        // "?authType=8&code="+ code +"&mobile="+mobile;
        return get(url,data);
    }

}

export  {
    DeviceApi
};