import React, {Component} from "react";
import SpeakLoading from "../components/speakLoading/SpeakLoading";
import {AiApi} from "../services/aiApi";
import styles from './ai.module.less'
import { v4 as uuidv4 } from 'uuid';
import {ChatApi} from '../services/chatApi.js';
import InterviewResult from '../components/interviewResult/interviewResult'
import '../assets/iconfont/iconfont.css'
import Loading from "../components/loading/loading";
import { Modal } from 'antd';
import Recorder from "../components/speechToText/recorder";
import voiceImg from "../assets/img/home/voice.png";
import listenImg from '../assets/img/home/listen.gif'

import AiLoading from '../components/aiLoading/aiLoading'
const chatApi = new ChatApi();
let aiApi = new AiApi();

class scanCode extends Component {
    chatConRef = React.createRef();
    constructor(props) {
        super();
        this.state = {
            buttonType: 1, // 1:开始面试 2：开始答题 3：查看报告
            chatList:[],
            content:'',
            indCode:"IND_1001",
            webGlId:14,
            keyId:'',
            chatRepId: "",  //用于做打断标记
            isModalOpen:false,
            chat_id:'',
            showLoading:true,
            toGetProblem:false,     //展示加载面试
            nowNum:1,               //当前问题
            totalNum:8,             //总问题数
            timeProblem:100,        //每道题现时
            replyFlag:false,        //是否开始回复
            strNum:0,               //回复语句数量
            strTxt:'',                  //短句内容
        }
        this.chat_temp_index = 0;
        this.chat_text_temp_index = 0;
        this.queue=[];

        this.timer = null; // 判断长按的定时器
        this.ws = null;
        this.replyTimer=null        //回答计时

    }

    componentDidMount() {
        window.addEventListener('SceneReady', this.closeLoading);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.setState({
            keyId:params.key,
            webGlId:params.webGlId,
        })

        const constraints = { audio: true };
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            this.recorder = new Recorder(stream, this.handleMsg);
        }, err => {
            switch (err.message || err.name) {
                case 'PERMISSION_DENIED':
                case 'PermissionDeniedError':
                    alert('用户拒绝提供信息。');
                    break;
                case 'NOT_SUPPORTED_ERROR':
                case 'NotSupportedError':
                    alert('浏览器不支持硬件设备。');
                    break;
                case 'MANDATORY_UNSATISFIED_ERROR':
                case 'MandatoryUnsatisfiedError':
                    alert('无法发现指定的硬件设备。');
                    break;
                default:
                    alert('无法打开麦克风。异常信息:' + (err.code || err.name));
                    break;
            }
        });
        this.connectWsExc(() => {
            setTimeout(() => {
                this.heartCheck()
            },2000)
        })
    }
    closeLoading= (res) => {
        console.log('overLoading')
        window.Player.current.avatar.setScale(0.8,0.8)
        window.Player.current.avatar.setPosition(0, -150, 0, true)
        this.setState({
            showLoading: false
        })
    }

    getContent=(e)=>{
        this.setState({
            content:e.target.value
        })
    }
    add=()=>{
        this.handleTouchEnd()
        let content=this.state.content+this.state.strTxt
        let item = {
            "reqId": "",
            "content": content,
            "user": "user",
            "status": 0
        }
        let arr=this.state.chatList
        arr.pop()
        arr.push(item)
        //添加机器人思考loading
        let obj={content:''}
        arr.push(obj)

        this.setState({
            chatList:arr,
            content:'',
            strNum:0
        },()=> {
            this.ask(content)
            this.scrollToBottom()
        })
    }
    ask=async(content)=>{
        if (content) {
            let param={
                ind_code: this.state.indCode,
                webgl_id: this.state.webGlId,
                content: content,
                key: 'app-4EyI4VBSPQR1B6xYDqoL8y6U'
            }
            await this.aiTalk(param);
        }
    }
    aiTalk = async (params) => {
        let chat_id = this.getChatId();
        window.PlayStats = {
            playCode: "",
            isLast: false //是否最后一个播报
        }
        // this.consoleTime("开始访问dify");
        const response = await fetch('/digApi/digital_dify/chat_word_v2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            dataType: "text/event-stream",
            body: JSON.stringify({
                "chat_id": chat_id,
                "user_id": "xiaogu",
                "ind_code": params.ind_code,
                "webgl_id": params.webgl_id,
                "key": params.key,
                "content": params.content
            })
        });

        this.chat_index = 0
        this.chat_temp_index = 1
        this.chat_text_temp_index = 1;

        if(!response.ok){
            throw new Error(`HTTP error! status: ${response.status}`);
            let data = {
                index: this.chat_index,
                voiceData: "",
                last: true,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            this.queue.push(data)
        }

        // this.consoleTime("dify返回结果");

        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let result = true;

        while (result) {
            const { done, value } = await reader.read();
            result = !done;

            if(this.state.chatRepId === "stop"){
                result = false
                continue;
            }

            if(done){
                this.chat_index ++;
                // window.tempPlayStatus = 1
                let data = {
                    index: this.chat_index,
                    voiceData: "",
                    last: true,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                this.queue.push(data)
                // this.props.addChatQueue(data)

            }

            // try {
            let text = decoder.decode(value);
            console.log("------------dify 返回内容------------")
            console.log(text)
            // alert(text)
            if(text){ //现在如果dify直接返回空则是 无法回答， 之后应该是固定的文案
                // text = text.replace("data: ", "")
                const formattedData = text.replace(/\n/g, '。');
                const dataEntries = text.trim().split('data: ').slice(1);

                for(var i=0; i < dataEntries.length; i++){
                    let entry = dataEntries[i];

                    try{
                        let json = JSON.parse(entry);
                        // console.log(json)
                        json.last = false;
                        this.chat_index ++;
                        await this.difyTalk(json, this.chat_index);
                        // if (!json.params||!this.state.dialogVisible){
                        //     this.scrollChatToBottom()
                        // }

                    }catch (e){
                        console.log(e)
                    }
                }
                this.scrollToBottom()
            }
        }
    }
    difyTalk = async (jsonStr,index) => {
        try{
            await this.aiPlay(jsonStr.content, jsonStr.reqId,jsonStr,index);
        }catch (e) {
            console.log(e)
            let data = {
                index: index,
                voiceData: "",
                last: false,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            this.queue.push(data)
            // this.props.addChatQueue(data)
        }

    }
    // 数字人播报
    aiPlay = async (content,rep_id,jsonStr,index) => {
        //无法回答您的问题
        if(content.indexOf("11111111111") !== -1){
            this.outputUnable(() => {
                let data = {
                    index: index,
                    voiceData: "",
                    last: false,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                this.queue.push(data)
            })

        }else{

            // if(index === 1){
            //     this.consoleTime("第一次从ali访问播报数据start");
            // }

            this.showChatText(jsonStr)

            await chatApi.getChatData(content).then(res => {

                // if(index === 1){
                //     this.consoleTime("第一次从ali访问播报数据end");
                // }
                if(this.state.chatRepId !== "stop"){
                    if(res.data && res.data.voiceBroadcastResult){
                        let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)
                        console.log(voiceData)

                        let data = {
                            index: index,
                            voiceData: voiceData,
                            last: jsonStr.last,
                            chatObj: jsonStr,
                            rep_id: rep_id,
                            openAudio: true
                        }
                        this.queue.push(data)

                        this.createTimer();
                        this.scrollToBottom()
                        console.log(this.state.chatList,'chatList')
                    }else{
                        // alert("未")
                    }
                }
            })
        }
    }
    createTimer = () => {
        if(this.timer == null){
            this.timer = setInterval(() => {
                this.execQueue();
            },100)
        }
    }
    outputUnable = (callback) => {
        //TODO 需要根据首页和弹框页面 操作不同的聊天记录对象
        let item = {
            "reqId": '',
            "content": '无法回答您的问题',
            "user": "assistants",
            "status": 1,
            'params':''
        }

        let arr=this.state.chatList
        let arrNew=this.state.newList
        arr[arr.length -1] = item;
        arrNew[arr.length -1] = item;
        this.setState({
            chatList:arr,
            newList:arrNew,
        },() => {
            this.scrollToBottom()
            if(callback){
                callback();
            }
        })
    }
    showChatText(jsonStr){
        let reqId = jsonStr.reqId;
        let textArr = jsonStr.content.split('');

        let lastItem = this.state.chatList[this.state.chatList.length - 1] ;
        let arr = this.state.chatList ;

        if (lastItem && lastItem.reqId && lastItem.reqId === reqId) {
            for (let i = 0; i < textArr.length; i++) {
                lastItem.content += textArr[i];
            }
            arr[arr.length - 1]=lastItem
        }else {

            for (let i = 0; i < textArr.length; i++) {
                if (i == 0) {
                    let item = {
                        "reqId": reqId,
                        "content": textArr[i],
                        "user": "assistants",
                        "status": 1,
                        "params":jsonStr.params
                    }
                    arr[arr.length -1] = item
                } else {
                    arr[arr.length-1].content += textArr[i];
                }
            }
            lastItem=arr[arr.length-1]
        }

        this.setState({
            chatList:arr,
        })
    }
    execQueue = () => {
        if(this.queue && this.queue.length > 0){
            let data = this.findAndCopy(this.queue, this.chat_temp_index)
            if(data !== null) {
                if (!window.Player.current.playing && window.tempPlayStatus === 0) {
                    // if(this.chat_temp_index === 1){
                    //     this.props.setAudioAction("startBroadcast")
                    // }

                    if (data.voiceData) {
                        window.tempPlayStatus = 1;
                        window.Player.current.setData(data.voiceData);

                        window.PlayStats = {
                            playCode: data.rep_id,
                            status: 1, // 0 ：未播报， 1：已开始播报，2：剧本
                            isLast: data.last //是否最后一个播报
                        }
                    }

                    this.chat_temp_index ++

                    if (data.last) {
                        // if(data.openAudio){
                        //     this.props.setAudioAction("endBroadcast")
                        // }else{
                        //     this.props.setAudioAction("changeFunctionPage")
                        // }
                        this.queue = [];
                    }

                }
            }
        }
    }
    findAndCopy = (list,index) => {
        if(list && list.length > 0){
            for(var i=0 ;i < list.length;i++){
                let data = list[i];
                if(data.index === index){
                    return this.copy(data);
                }
            }
        }
        return null;
    }
    copy = (data) => {
        let copystr = JSON.stringify(data);
        let copy = JSON.parse(copystr);
        return copy;
    }
    getChatId = () => {
        console.log("----------------chat_id------------------");
        console.log(this.state.chat_id);

        if(!this.state.chat_id){
            this.state.chat_id = uuidv4();
        }

        return this.state.chat_id;
    }
    handleOk=()=>{
        this.setState({
            isModalOpen:true
        })
    }
    handleCancel=()=>{
        this.setState({
            isModalOpen:false
        })
    }

    heartCheck = () => {
        this.websocketTimer = setInterval(() => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws.send("ping");
            }else{
                this.connectWsExc();
            }
        }, 3000);
    }

    connectWsExc = (callback) => {
        let websocket=new WebSocket(this.getWsUrl())
        websocket.onopen = () => {
            console.log('open websocket');
            if(callback){
                callback();
            }
        };

        let that=this
        let timer=null
        let num=0
        websocket.onmessage= (res)=> {
            let arr=this.state.chatList
            if (num==0){
                let item = {
                    "reqId": "",
                    "content": '',
                    "user": "user",
                    "status": 0
                }
                arr.push(item)
            }
            clearTimeout(timer)
            num++
            let obj=JSON.parse(res.data)
            console.log('接收到服务器返回的消息：' + res.data);
            let str=''
            if(that.state.strNum ==obj.index){
                str=obj.text
                this.setState({
                    strTxt:str,
                })
            }else{
                let str= that.state.content+that.state.strTxt
                this.setState({
                    content:str,
                    strNum:obj.index,
                    strTxt:obj.text
                })
            }

            this.setState({
                chatList:arr,
            },()=>{
                console.log('num',that.state.strTxt,2,this.state.content);
                this.scrollToBottom()
            })
            timer=setTimeout(()=>{
                // this.add()
                // num=0
            },2000)
        }

        websocket.onclose =()=>{
            console.log('关闭ws');
        }

        // setWs(websocket)
        this.ws = websocket;
    }
    getWsUrl = () => {
        let chat_id = this.getChatId();
        var baseUrl ='wss://digapitest.sangucloud.com/asr/'+ chat_id;
        return baseUrl
    }
    handleTouchStart = () => {
        this.timer = setTimeout(() => {
            this.recorder.start();
        }, 300);
    }

    handleTouchEnd = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        setTimeout(()=>{
            this.recorder.stop();
            const { onEnd } = this.props;
            onEnd && onEnd(this.recorder.getBlob());
        },200)


        // this.blobToBase64(this.recorder.getBlob(),res=>{
        //     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        //         console.log('send to ws');
        //         this.ws.send("{pcm}" + res);
        //     }
        // })

    }

    // 处理录音的回调
    handleMsg = (data) => {
        // const { onProgress } = this.props;
        // onProgress && onProgress(data);
        var blob=new Blob([data],{type:"audio/pcm"})
        this.blobToBase64(blob,res=>{
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                console.log('send to ws');
                this.ws.send("{pcm}" + res);
            }
        })


    }
    blobToBase64(blob,callback) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
            // 调用回调函数，返回完整的DataURL
            let base64data = reader.result.split(',')[1];
            callback (base64data);
        };
    }
    scrollToBottom = () => {
        setTimeout(() => {
            try {
                if (this.chatConRef.current) {
                    this.chatConRef.current.scrollTop = this.chatConRef.current.scrollHeight;
                }
            } catch (e) {
                console.log(e);
            }
        },500)

    }
    //点击开始面试
    toBegin=()=>{
        this.setState({
            buttonType:2,
            toGetProblem:true,
        })
        this.countDown()
        this.handleTouchStart()
        window.Player.current.avatar.setPosition(-100, -150, 0, true)
        setTimeout(()=>{
            this.setState({
                toGetProblem:false,
            })
        },500)
    }
    //下一个问题
    nextQuestion=()=>{
        let num=this.state.nowNum
        this.setState({
            nowNum:num+1,
            chatList:[],
            timeProblem:100
        },()=>{
            this.countDown(1)
            this.handleTouchEnd()
            setTimeout(()=>{
                this.handleTouchStart()
            },200)
        })
    }
    //完成面试
    overView=()=>{
        this.setState({
            buttonType:3
        },()=>{
            this.handleTouchEnd()
        })
    }
    countDown=(e)=>{
        let num=this.state.timeProblem
        if(e==1){
            clearInterval(this.replyTimer)
        }
        this.replyTimer=setInterval(()=>{
            if (num>0){
                this.setState({
                    timeProblem:num--
                })
            }else{
                clearInterval(this.replyTimer)
                if (this.state.nowNum==this.state.totalNum){
                    this.overView()
                }else{
                    this.nextQuestion()
                }
            }
        },1000)
    }
    render() {
        return (
            <div>
                {
                    this.state.showLoading?(
                        <Loading></Loading>
                    ):(
                        <div className={styles.aiHome}>
                            <div className={styles.chatCon}>
                                <div className={styles.chatItem} ref={this.chatConRef}>
                                    <div className={styles.itemCon}>
                                        {
                                            this.state.chatList.map((item, index) => (
                                                item.user === 'user'? (
                                                    <div key={index} className={styles.userCon} >
                                                        <p className={`${styles.bgBlue} ${styles.robotItem}`} >
                                                            {
                                                                item.content||this.state.content||this.state.strTxt?(
                                                                    <>
                                                                        {item.content ? item.content : this.state.content + this.state.strTxt}
                                                                    </>
                                                                ):('')
                                                            }
                                                        </p>
                                                    </div>
                                                ):(
                                                    <div key={index} className={styles.robotCon}>
                                                        {
                                                            item.content ? (
                                                                <div className={styles.robotItem}>
                                                                    {item.content}
                                                                </div>
                                                            ) : (
                                                                <div className={styles.robotItem}>
                                                                    <SpeakLoading></SpeakLoading>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.toGetProblem?(
                                    <div className={styles.beginLoading}>
                                        <section>
                                            <AiLoading></AiLoading>
                                        </section>
                                        <p>正在准备面试问题</p>
                                    </div>
                                ):('')
                            }
                            <div className={styles.bottomCon}>
                                {
                                    this.state.buttonType==1?(
                                        <div className={styles.begin} onClick={()=>this.toBegin()}>
                                            <button>开始面试</button>
                                        </div>
                                    ):('')
                                }
                                {
                                    this.state.buttonType==2?(
                                        <div className={styles.answer}>
                                            <aside className={styles.answerL}>
                                                <img src={voiceImg} alt=""/>
                                                <span>开始答题（{this.state.timeProblem}s）</span>
                                            </aside>
                                            <aside className={styles.answerR}>
                                                {
                                                    this.state.nowNum==this.state.totalNum?(
                                                        <button onClick={()=>this.overView()}>完成面试({this.state.nowNum}/{this.state.totalNum})</button>
                                                    ):(
                                                        <button onClick={()=>this.nextQuestion()}>下一题({this.state.nowNum}/{this.state.totalNum})</button>
                                                    )
                                                }
                                            </aside>
                                        </div>
                                    ):('')
                                }
                                {
                                    this.state.buttonType==3?(
                                        <div className={styles.begin}>
                                            <button>查看详细面试报告</button>
                                        </div>
                                    ):('')
                                }

                                {/*<div className={styles.rightBottom}>*/}
                                {/*    {*/}
                                {/*        this.state.inputType==1?(*/}
                                {/*            <div className={styles.inputBlock}>*/}
                                {/*                <Input type="text" className={styles.inputCon} value={this.state.content} onChange={this.getContent}/>*/}
                                {/*                <i className="iconfont icon-c042xiangshangjiantou" onClick={()=>this.add()}></i>*/}
                                {/*            </div>*/}
                                {/*        ):(*/}
                                {/*            <div className={styles.btnSpeak}>*/}
                                {/*                <button onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}>*/}
                                {/*                    {this.state.inputText}*/}
                                {/*                </button>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </div>
                            <Modal title="Basic Modal" open={this.state.isModalOpen} onOk={()=>this.handleOk} onCancel={()=>this.handleCancel} footer={null}>
                                <InterviewResult keyId={this.state.keyId}></InterviewResult>
                            </Modal>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default scanCode