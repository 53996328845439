import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"
const digCApi = "/digCApi/"

class AudioApi{

    async voiceToTextTxBase64(base64Str){

        var url = api + "system/ali/digital/voiceToTextTxBase64";


        let data = {
            base64String: base64Str
        }

        return post(url,data);
    }

    async sentence_asr(base64Str){

        var url = digCApi + "asr/tencent/sentence_asr";


        let data = {
            data: base64Str
        }

        return post(url,data);
    }

    async sentence_asr_blob(formData){

        var url = digCApi + "asr/tencent/sentence_asr_blob";

        return post(url,formData);
    }



}

export  {
    AudioApi
};