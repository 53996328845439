import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"

class ChatApi{
    async getChatData(content){

        var url = api + "system/ali/digital/ttsa";

        let params = {
            userQuery: content,
            tenantCode: "5SS_D3aQ",
            bizName: "xiaogu",
            dataType: "textDrivenAvatar",
            avatarId: 24300606,
            extParams: {
                resultType:"afts"
            }
        }

        // let params = {
        //     dataType: "chat",
        //     userQuery: "你可以向公司的管理层或人力资源部门提出申诉并解释情况,如果无法解决，你可以咨询劳动仲裁部门或劳动监察部门，他们会给你进一步的建议和帮助。"
        // }

        let data = {
            targetMethod: "avatarRequest",
            methodParams: JSON.stringify(params),
            commodityCode: "P_STD_AVATAR_MAJOR"
        }

        return post(url,data);
    }

    async init(){

        var url = api + "system/ali/digital/ttsa";
        let params = {
            avatarId: 24300606,
            dataType: "broadcast"
        }

        let data = {
            targetMethod: "init",
            methodParams: JSON.stringify(params),
            commodityCode: "P_STD_AVATAR_MAJOR"
        }

        return post(url,data);
    }


    async getBroadcast(dataId){

        var url = api + "system/ali/digital/ttsa";
        let params = {
            dataType: "broadcast",
            dataId: dataId
        }

        let data = {
            targetMethod: "avatarRequest",
            methodParams: JSON.stringify(params),
            commodityCode: "P_STD_AVATAR_MAJOR"
        }

        return post(url,data);
    }

    async blockChat(chatId, content,key){

        var url = digApi + "dify/chat";
        let params = {
            key: key,
            chat_id: chatId,
            content: content,
            ind_code: '',
            response_mode: "blocking",
            inputs: ""
        }

        return post(url,params);
    }

}

export  {
    ChatApi
};