export default {
    timerData: {

        time: 0,
        limit: 0
    }
    ,
    timerType: '',
    broadKey: '',

    audioAction: "", //  startAudio：开始收音 closeAudio：停止收音  receiveAudioText：接收到语音文字  sendAudioText: 发送语音文本  startBroadcast：开始播报 stopBroadcast：打断播报  endBroadcast：播报完成

    chatRepId: "",   // 聊天回复id

    chatQueue: []
}