import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"
const digCApi = "/digCApi/"
const sgApi = "/sgApi/"

class PositionApi{
    //急聘
    urgentCommunityAndHroList(params){
        let url = sgApi + "position/api/community/position/get/urgentCommunityAndHroList";
        return get(url,params);
    }
    //转换经纬度
    coordinateTransformationByBaiDu(params){
        let url = sgApi + "basicdata/openApi/coordinateTransformationByBaiDu";
        return get(url,params);
    }
    getPositionListByDigital(params){

        var url = api + "system/api/sangu/getPositionListByDigital";

        return post(url,params);
    }
    //企业直招
    getDirectDetail(params){
        let url = api + "system/api/sangu/get/community/position";
        return get(url,params);
    }
    //外包派遣
    getOutDetail(params){
        let url = api + "system/api/sangu/getHro";
        return get(url,params);
    }

    //投递记录
    getRecommondRecord(params){
        let url = sgApi + "project/api/project/getDigitalMyHroAndCommunityRecommendList";
        return get(url,params);
    }

    //投递职位
    signUp(params){
        let url = digCApi +  "digital_face/dig_user_complete_info_send";
        return post(url,params);
    }
    //同步
    synchronizePosition(params){
        let url = api +  "system/digital/auth/synchronize/position";
        return post(url,params);
    }
    // 支付宝人才就业应聘信息同步
    sendSynchronizePosition(params){
        let url = api +  "system/digital/auth/synchronize/send/info";
        return post(url,params);
    }

    // 获取token
    getDigToken(params){
        let url = api + "system/web/gl/getDigToken"
        return get(url,params);
    }
    // 获取投递记录
    getDigitalMyHroAndCommunityRecommendList(params){
        let url = sgApi + "project/api/project/getDigitalMyHroAndCommunityRecommendList";
        return get(url,params);
    }

    // 获取地区
    getLabourPositionCityAndCount(params) {
        let url = sgApi + 'position/api/community/position/getLabourPositionCityAndCount';
        return post(url, params);
    }

}

export  {
    PositionApi
};