let common = {};









common.formatDate = (dateStr, fmt) =>{

    if(dateStr == undefined || dateStr == null || dateStr == ''){
        return '';
    }

    let date = new Date(dateStr);

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}

common.isEmpty = (value) => {
    if(value === undefined || value === null || value === ''){
        return true;
    }

    return false;
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}





common.isEmptyArray = (value) => {
    if(value === undefined || value === null || value.length == 0){
        return true;
    }

    return false;
}


common.checkEmpty = (value) => {
    if(value == -1){
        return '';
    }

    return value;
}

common.hasPermi = (value, params) => {
    let permissions = params.map((item) => Number(item.id));
    if (value && !permissions.includes(8)) {
        if (!permissions.includes(value)) {
            return false;
        }else {
            return true;
        }
    }else {
        return true;
    }
}

export default common;
