
const screeningVariable = {
    positionTypeList: [
        { name: '其他', value: '0' },
        { name: '全职', value: '1' },
        { name: '兼职', value: '2' },
        { name: '零工', value: '3' },
        { name: '实习', value: '4' },
    ],

    // 零工之家
    sidelineList: [
        { name: '兼职', value: '2' },
        { name: '零工', value: '3' },
    ],

    workliftList: [
        { name: '不限', value: '0' },
        { name: '1年以下', value: '1' },
        { name: '1-3年', value: '2' },
        { name: '3-5年', value: '3' },
        { name: '5-10年', value: '4' },
        { name: '10年以上', value: '5' },
    ],

    eduList: [
        { name: '不限', value: '0' },
        { name: '小学', value: '1' },
        { name: '初中', value: '2' },
        { name: '中专', value: '3' },
        { name: '高中', value: '4' },
        { name: '专科', value: '5' },
        { name: '本科', value: '6' },
        { name: '硕士、专业学位硕士', value: '7' },
        { name: '学术型硕士', value: '8' },
        { name: 'MBA', value: '9' },
        { name: 'EMBA', value: '10' },
        { name: '博士、专业学位博士', value: '11' },
        { name: '学术型博士', value: '12' },
        { name: '博士后', value: '13' },
    ],

    salaryList: [
        { name: '不限', value: '0' },
        { name: '1千以下', value: '1' },
        { name: '1千-2千', value: '2' },
        { name: '2千-3千', value: '3' },
        { name: '3千-5千', value: '4' },
        { name: '5千-8千', value: '5' },
        { name: '8千-1.5万', value: '6' },
        { name: '1.5万-2万', value: '7' },
        { name: '2万以上', value: '8' },
    ]
}

export default screeningVariable;