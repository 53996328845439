import {Component} from "react";
import verifyStyle from './verify.module.less'
import defaultIcon from '../../../assets/img/defaultIcon.png'
import filters from "../../../utils/filters.js";
import {PositionApi} from "../../../services/positionApi.js";
import recordStyle from "../../record/deliveryRecord.module.less";
import PageLoading from '../../speakLoading/pageLoading'

import { connect } from 'react-redux';
import {setTimerType, clearTimerType,setBroadKey} from '../../../store/actions'
import btnClick from "../../../assets/click1.wav";
const audio = new Audio(btnClick);

let positionAi = new PositionApi();
class verify extends Component {
    
    constructor(props) {
        super();
        this.state = {
            poisitinInfoData: props.poisitinInfoData,
            userInfoData: props.userInfoData,
            comeNum: props.comeNum,
            sn: props.sn,
            details: {},
            loading:false,
            itemObj:{
                name:'',
                mobile:''
            },
        }

    }
    componentDidMount() {
        this.handleInform()
        this.getInfo();
        // alert("进入到信息确认页面")
        this.props.setTimerType("verify");
        this.props.setBroadKey("verify")

        // let userName = this.replaceName(this.state.userInfoData.user_name);
        // let mobile = this.replacePhoneNumber(this.state.userInfoData.mobile);
        //
        // alert(userName)
        // alert(mobile)

        // if(this.state.userInfoData){
        //     this.setState({
        //         userShowInfo: {
        //             user_name: userName,
        //             mobile: mobile
        //         }
        //     })
        // }

    }

    replacePhoneNumber = () => {
        const { phoneNumber } = this.state;
        const maskedPhoneNumber = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        return maskedPhoneNumber
    };

    // replaceName = () => {
    //     const { name } = this.state;
    //     const maskedName = name.replace(/(?<=^.{1}).*(?=.)/, '*');
    //     return maskedName
    // };

    componentWillUnmount() {
        this.props.clearTimerType();
    }*
    //点击找工作声音
    btnClickVoice(){
        audio.play();
    }
    getInfo = () => {
        this.setState({
            loading:true
        })

        if (this.state.poisitinInfoData.employmentType == 3) {  // 企业直招
            let params = {
                positionId: this.state.poisitinInfoData.positionId
            }
            positionAi.getDirectDetail(params).then((res) => {
                if(res.data.data) {
                    if (res.data.data.orgInfoDto && res.data.data.orgInfoDto.ceLogo){
                        res.data.data.orgInfoDto.ceLogo=res.data.data.orgInfoDto.ceLogo+'?x-oss-process=image/resize,m_fill,w_60,quality,q_60'
                    }else if(res.data.data.orgLogo){
                        res.data.data.orgLogo=res.data.data.orgLogo+'?x-oss-process=image/resize,m_fill,w_60,quality,q_60'
                    }

                    this.setState({
                        details: res.data.data
                    })
                }
                this.setState({
                    loading:false
                })
            })
        }else { // 外包派遣
            let params = {
                positionHroId: this.state.poisitinInfoData.positionId
            }
            positionAi.getOutDetail(params).then((res) => {
                if(res.data.data) {
                    if (res.data.data.orgInfoDto && res.data.data.orgInfoDto.ceLogo){
                        res.data.data.orgInfoDto.ceLogo=res.data.data.orgInfoDto.ceLogo+'?x-oss-process=image/resize,m_fill,w_60,quality,q_60'
                    }else if(res.data.data.orgLogo){
                        res.data.data.orgLogo=res.data.data.orgLogo+'?x-oss-process=image/resize,m_fill,w_60,quality,q_60'
                    }

                    this.setState({
                        details: res.data.data
                    })
                }
                this.setState({
                    loading:false
                })
            })
        }
    }

    // 返回
    handleBack = () => {
        this.btnClickVoice()
        this.props.onUserInfoBack(1);
    }

    // 投递
    handleSend = () => {
        this.btnClickVoice()
        this.setState({
            loading:true
        })
        let params = {
            scan_type: this.state.comeNum==3?8:6,   // 6：支付宝刷脸，7扫码，8刷身份证，9支付宝小程序
            user_name: this.state.userInfoData.user_name ? this.state.userInfoData.user_name : this.state.userInfoData.nick_name,   // 姓名
            mobile: this.state.userInfoData.mobile,   // 手机号
            id_card: this.state.userInfoData.id_card,   // 身份证号
            ind_code: this.state.details.indCode?this.state.details.indCode:this.state.poisitinInfoData.indCode,
            position_type: this.state.poisitinInfoData.employmentType == 3 ? 2 : 1,   // 1外包派遣 2企业直招
            position_id: this.state.poisitinInfoData.employmentType == 3 ? this.state.details.projectId : this.state.details.positionHroId,
            sn: this.state.sn
        }
        // alert(JSON.stringify(params))
        positionAi.signUp(params).then((res) => {

            this.props.onDeliverSuccess(res,this.state.userInfoData.mobile);
            if(res.data.code === 200){
                /* global my */
                if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                    my.postMessage({type:"toSendMsg", userInfo:this.state.userInfoData, position:this.state.details, outApplyId: res.data.data });
                }
            }

            this.setState({
                loading:false
            })
        })
    }

    //处理名字
    handleInform(){
        let name=''
        let mobile=''
        if (this.state.userInfoData.user_name){
            name=this.state.userInfoData.user_name.charAt(0)
            name=name+this.getNameStar(this.state.userInfoData.user_name)
        }
        if (this.state.userInfoData.mobile){
            mobile =this.state.userInfoData.mobile.substring(0, 3)
            mobile=mobile+this.getMobileStar(this.state.userInfoData.mobile)
            mobile=mobile+this.state.userInfoData.mobile.slice(-4)
        }
        this.setState({
            itemObj:{
                name,
                mobile
            }
        })
    }
    getMobileStar(e){
        let num=e.length-8
        let str=''
        for (let i=0;i<=num;i++){
            str=str+'*'
        }
        return str
    }
    getNameStar(e){
        let num=e.length-2
        let str=''
        for (let i=0;i<=num;i++){
            str=str+'*'
        }
        return str
    }
    render() {
        return (
            <div style={{height: '80vh'}}>
                {
                    this.state.loading?(
                        <div className={verifyStyle.loading}>
                            <PageLoading></PageLoading>
                        </div>
                    ):(
                        <div className={verifyStyle.verify}>
                            {/*<h1>报名信息确认</h1>*/}
                            <h1></h1>
                            <div className={verifyStyle.inform}>
                                <aside>
                                    <span>姓名</span>
                                    <p>{this.state.itemObj.name}</p>
                                </aside>
                                <aside>
                                    <span>手机</span>
                                    <p>{ this.state.itemObj.mobile }</p>
                                </aside>
                            </div>
                            <div className={`${verifyStyle.inform} ${verifyStyle.informHeight}`}>
                                {/*<h1>职位信息</h1>*/}
                                <div className={verifyStyle.position}>
                                    <section>
                                        <div className={verifyStyle.name}>{ this.state.details.countyName }|{ this.state.details.positionName }</div>
                                        <div className={verifyStyle.money}>
                                            <span>
                                                {this.state.details.positionType === 0 && this.state.details.minSalary && this.state.details.maxSalary &&
                                                `${this.state.details.minSalary}-${this.state.details.maxSalary}元•${this.state.details.salaryMonths?this.state.details.salaryMonths:12}薪`}
                                            </span>
                                            <span>
                                                {this.state.details.positionType === 0 && this.state.details.discussPersonally === 1 &&
                                                '面议'}
                                            </span>
                                            <span>
                                                {this.state.details.positionType !== 0 && this.state.details.partTimeSalary ? `${this.state.details.partTimeSalary} ${filters.getSalaryType(this.state.details.partTimeSalaryType)}`:''}
                                            </span>
                                            <span>
                                                {
                                                    this.state.details.positionType !== 0&&this.state.details.minSalary&&this.state.details.maxSalary&&this.state.poisitinInfoData.employmentType!==3 ?
                                                        `${this.state.details.minSalary}-${this.state.details.maxSalary}元/月/人`:''
                                                }
                                            </span>
                                        </div>
                                    </section>
                                    <div className={verifyStyle.listType}>
                                        {
                                            this.state.details.subsidyLabel&&this.state.details.subsidyLabel.length>0 ? this.state.details.subsidyLabel.map((item, index) => (
                                                 item !== '不限' ? <p key={index}><span>{item}</span></p>:''
                                            )):''
                                        }
                                    </div>
                                    <div className={verifyStyle.company}>
                                        <div className={verifyStyle.companyInform}>
                                            <h2>{this.state.details.orgInfoDto ? this.state.details.orgInfoDto.ceName:this.state.details.enterpriseName}</h2>
                                            <p>
                                                <span>{this.state.details.orgInfoDto && this.state.details.orgInfoDto.natureStr ? ` ${this.state.details.orgInfoDto.natureStr}`:`${filters.getNatureText(this.state.details.nature)}`}</span>
                                                <span>{this.state.details.orgInfoDto && this.state.details.orgInfoDto.enterpriseScaleStr ? ` | ${this.state.details.orgInfoDto.enterpriseScaleStr}`:`| ${ filters.getDefaultValue(this.state.details.orgScale, '未填写')}`}</span>
                                                <span>{` | ${this.state.details.orgInfoDto&&this.state.details.orgInfoDto.industryStr?this.state.details.orgInfoDto.industryStr:(this.state.details.industryName?this.state.details.industryName:'未填写')}`}</span>
                                            </p>
                                        </div>
                                        <img src={this.state.details.orgInfoDto && this.state.details.orgInfoDto.ceLogo ? this.state.details.orgInfoDto.ceLogo : (this.state.details.orgLogo?this.state.details.orgLogo:defaultIcon)} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className={verifyStyle.btn}>
                                <aside>
                                    <button className={verifyStyle.check} onClick={ this.handleBack }>取消</button>
                                    <button className={verifyStyle.over} onClick={ this.handleSend }>确认，报名</button>
                                </aside>
                            </div>
                        </div>
                    )
                }

            </div>

        );
    }
}
const mapDispatchToProps = dispatch => ({
    setTimerType: (timerType) => {
        dispatch(setTimerType(timerType))
    },
    clearTimerType: () => dispatch(clearTimerType()),
    setBroadKey: (broadKey) => {
        dispatch(setBroadKey(broadKey))
    },
});
export default connect(null, mapDispatchToProps)(verify);
// export default verify;