import React, { useState, useEffect,useRef } from 'react';
import styles from './positionList.module.less'
import filters from '../../utils/filters.js';
import hotIcon from '../../assets/img/urgent.png';
import {PositionApi} from "../../services/positionApi.js";
import PageLoading from '../speakLoading/pageLoading'
import resumeDefault from '../../assets/img/resume_default.png'

import { useDispatch } from 'react-redux';
import {setTimerData, clearTimerData, setTimerType, clearTimerType} from '../../store/actions'
import { Carousel } from 'antd';

import last from '../../assets/img/home/last.png'
import next from '../../assets/img/home/next.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
const PositionList = ({toWork,indCode, chatObj, onData,refreshCount, modalRef, onArea, onScreen,selectScreenData,areaData, onCleanScreenAll }) => {
    let positionAi = new PositionApi();
    const dispatch = useDispatch();

    const [positionHotData, setPositionHotData] = useState(Array)
    const [hotList, setHotList] = useState(Array)
    const [loading, setLoading] = useState(false);
    let pageOption = useRef({
        pageSize: 10,
        pageNo: 0,
        total: 0,
        pageCount: 0,
        positionTypeInt:0,
        other:{}
    })
    const [previousPage, setPreviousPage] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const [firstLoading, setFirstLoading] = useState(false);

    //顶部按钮
    const [chooseBtn, setChooseBtn] = useState(0);

    const [chooseCity, setChooseCity] = useState('');
    const [chooseNum, setChooseNum] = useState(0);

    const lastExecutedTimeRef = useRef(0)


    useEffect(() => {
        dispatch(setTimerType("positionList"))
        return () => {
            console.log("positionList 卸载--------------------------------")
            dispatch(clearTimerType())
        }

    }, []);
    //急聘
    useEffect(() => {
        setHotList([])
        if(toWork==1){
            setChooseBtn(1)
        }

    }, [refreshCount]);

    useEffect(() => {
        let hasValue = false;

        // 遍历 areaData 对象
        Object.values(areaData).forEach(({ value, label }) => {
            if (value || label) {
                hasValue = true;
            }
        });

        // 遍历 selectScreenData 对象
        let num=0
        Object.values(selectScreenData).forEach(value => {
            if (value) {
                hasValue = true;
                num++
            }
        });
        if (num>0){
            setChooseNum(num)
        }
        console.log(pageOption.current,hasValue,'&&&&&&&',selectScreenData)

        if (hasValue) {
            setPositionHotData([])
            let positionTypeInt=localStorage.getItem('positionTypeInt')?localStorage.getItem('positionTypeInt'):1
            localStorage.removeItem('positionTypeInt')

            if (positionTypeInt){
                setChooseBtn(positionTypeInt)
            }
            console.log(areaData)

            let area=''
            if (areaData.city.label&&areaData.city.label!=='全部'){
                if (areaData.county.label.indexOf('全部')>-1){
                    area=areaData.city.label
                }else{
                    area=areaData.city.label+areaData.county.label
                }
            }
            setChooseCity(area)
            let other={
                ...pageOption.current.other,
                ...selectScreenData,
                ...areaData
            }
            pageOption.current.positionTypeInt=positionTypeInt
            pageOption.current.pageNo=0
            pageOption.current.total=0
            pageOption.current.pageCount=0
            pageOption.current.other=other

            let newObj=JSON.stringify(pageOption.current)
            localStorage.setItem('listSearch',newObj)


            if (pageOption.current.pageNo===0){
                if (chooseBtn==4){
                    getHotList()
                }else {
                    getPositionList(pageOption.current)
                }
            }
        }else{
            let positionTypeInt=localStorage.getItem('positionTypeInt')
            if (toWork==1){
                positionTypeInt=positionTypeInt?positionTypeInt:1
                localStorage.removeItem('positionTypeInt')
            }else {
                positionTypeInt=''
            }
            setPositionHotData([])
            // if (pageOption.pageNo===0){
                if (positionTypeInt==4){
                    pageOption.current.positionTypeInt=positionTypeInt
                    pageOption.current.pageNo=0
                    pageOption.current.total=0
                    pageOption.current.pageCount=0
                    setChooseBtn(positionTypeInt)
                    getHotList()
                }else {
                    if (positionTypeInt){
                        setChooseBtn(positionTypeInt)
                        pageOption.current.positionTypeInt=positionTypeInt
                        pageOption.current.pageNo=0
                        pageOption.current.total=0
                        pageOption.current.pageCount=0
                        pageOption.current.other={}
                        getPositionList(pageOption.current)
                    }else{
                        pageOption.current.positionTypeInt=positionTypeInt
                        pageOption.current.pageNo=0
                        pageOption.current.total=0
                        pageOption.current.pageCount=0
                        pageOption.current.other={}
                        console.log(pageOption.current,'@@@@@@@@@@@@@666666666666')
                        getPositionList(pageOption.current)
                    }
                }
            // } else{
            //     pageOption.current.positionTypeInt=positionTypeInt
            //     pageOption.current.pageNo=0
            //     pageOption.current.total=0
            //     pageOption.current.pageCount=0
            //     pageOption.current.other={}
            //     if (positionTypeInt){
            //         setChooseBtn(positionTypeInt)
            //     }
            //     getPositionList(pageOption.current)
            // }
        }
    }, [selectScreenData,areaData,chatObj]);

    // 获取职位列表
    const getPositionList = (e) =>{
        setLoading(true);
        let obj=e?e:pageOption.current
        console.log('@@@@@@@@@',obj)
        let locationCode=''
        if (obj.other&&obj.other.county&&obj.other.county.value){
            locationCode=obj.other.county.value
        }else if(obj.other&&obj.other.city&&obj.other.city.value){
            locationCode=obj.other.city.value
        }
        let positionTypeInt=''
        if (toWork==1){
            if (e){
                positionTypeInt=e.positionTypeInt
            }else if (pageOption.current.positionTypeInt){
                positionTypeInt=pageOption.current.positionTypeInt
            }else{
                positionTypeInt=1
            }
        }
        let params = {
            ...chatObj,
            indCode: indCode,
            pageNo: e?e.pageNo:(pageOption.current.pageNo ? pageOption.current.pageNo : 0),
            pageSize: pageOption.current.pageSize,
            positionTypeInt,
            workingLifeInt:e?e.other.workingLife:pageOption.current.other.workingLife,
            educationInt:e?e.other.education:pageOption.current.other.education,
            locationCode:locationCode,
            salaryInt:e?e.other.salary:pageOption.current.other.salary,
        }
        positionAi.getPositionListByDigital(params).then((res) => {
            if(res.data.data.list) {
                pageOption.current.total=res.data.data.count
                pageOption.current.pageCount=res.data.data.page_count

                setPositionHotData( prevData => prevData.concat(res.data.data.list));
                setLoading(false);
                setFirstLoading(false)
            }

        });
    }
    //获取急聘列表
    const getHotList=()=>{
        setLoading(true);
        // setFirstLoading(true)
        let params = {
            indCode: indCode,
            pageNo: pageOption.current.pageNo ? pageOption.current.pageNo : 0,
            pageSize: pageOption.current.pageSize,
        }
        positionAi.urgentCommunityAndHroList(params).then((res) => {
            console.log(res)
            if(res.data.data.list) {
                setPositionHotData(prevData => prevData.concat(res.data.data.list))

                pageOption.current.total=res.data.data.count
                pageOption.current.pageCount=res.data.data.page_count

                setLoading(false);
                setFirstLoading(false)
            }

        });
    }
    // 跳转职位详情 向父组件传参
    function routTo(data) {
        localStorage.setItem('positionTypeInt',chooseBtn);
        const dataToSend = data;
        onData(dataToSend);
        
    }
    
    function refreshTimer() { }

    // 上一页
    function lastPage() {
        if (!previousPage) {
            setPreviousPage(true);
            setTimeout(() => {
                if(pageOption.pageNo) {
                    pageOption.current.pageNo=pageOption.current.pageNo - 1
                }
                setPreviousPage(false);
            }, 800);
        }
    }
    // 下一页
    function nexPage() {
        if (!nextPage) {
            setNextPage(true);
            setTimeout(() => {
                pageOption.current.pageNo= pageOption.current.pageNo == null ? 1 : pageOption.current.pageNo + 1
                let obj= localStorage.getItem('listSearch')
                if (obj){
                    obj=JSON.parse(obj)
                    obj.pageNo++
                    let newObj=JSON.stringify(obj)
                    localStorage.setItem('listSearch',newObj)
                }
                setNextPage(false);
            }, 800);
        }
    }

    //分页
    useEffect(() => {
        let obj= localStorage.getItem('listSearch')
        obj=JSON.parse(obj)
        console.log('dddddddddddddddd',obj)
        if(pageOption.current.pageNo !== 0){
            if(chooseBtn==4){
                getHotList()
            }else{
                if (obj){
                    // obj.pageNo++;
                    let newObj=JSON.stringify(obj)
                    localStorage.setItem('listSearch',newObj)
                    getPositionList(obj); // 获取数据的函数
                }else {
                    console.log('$$$$$$$$$$$$',obj,pageOption.current)
                    // pageOption.current.pageNo++
                    getPositionList(pageOption.current); // 获取数据的函数
                }
            }
        }
    }, [pageOption.current.pageNo]);

    const handleScroll = (event) => {
        // console.log(event, '滚动事件');
        let el = event.target;
        if( el.scrollTop + el.clientHeight + 2 >= el.scrollHeight ) {
            if(pageOption.current.pageNo<pageOption.current.pageCount) {
                const now = Date.now()
                if (now - lastExecutedTimeRef.current > 1000) {

                    lastExecutedTimeRef.current = now;
                    // if ((pageOption.current.pageNo == null || pageOption.current.pageNo < pageOption.current.pageCount) && pageOption.current.pageCount !== 1) {
                    if(el.scrollTop>0) {
                        setLoading(true);
                        nexPage();
                    }
                    // }
                }
            }
        }
    };

    const card = useRef();
    const swiperRef = useRef(null);
    function getSwiper(){
        const swiperInstance = swiperRef.current;
        // 修改导航按钮颜色
        if (swiperInstance) {
            swiperInstance.children[1].style.color = '#A6D1FB';
            swiperInstance.children[2].style.color = '#A6D1FB';
        }
    }

    const changeType=(e)=>{
        setChooseBtn(e)
        localStorage.setItem('positionTypeInt',e);
        localStorage.removeItem('listSearch')
        setLoading(true);
        setChooseCity('')
        setChooseNum(0)
        let type=null
        if (e==1){
            type=1
        }else if (e==2){
            type=2
        }else if(e==3){
            type=3
        }else {
            type=4
        }
        console.log('type:',type)
        let other={
            ...pageOption.current.other,
        }
        pageOption.current.positionTypeInt=type
        pageOption.current.pageNo=0
        pageOption.current.total=0
        pageOption.current.pageCount=0
        pageOption.current.other=other
        setPositionHotData([])
        onCleanScreenAll();
    }
    // 点击地区
    const handleArea = () => {
        localStorage.setItem('positionTypeInt',chooseBtn)
        let params = {
            positionTypeInt: pageOption.current.positionTypeInt,
            educationInt: pageOption.current.other.education,
            workingLifeInt: pageOption.current.other.workingLife,
            salaryInt: pageOption.current.other.salary,
            locationCode: pageOption.current.other.county?pageOption.current.other.county.value:'',
        };
        onArea(params);
    }

    // 点击筛选
    const handleScreen = () => {
        localStorage.setItem('positionTypeInt',chooseBtn)
        onScreen();
    }
    return (
        <div className={styles.positionList} onClick={refreshTimer}  onScroll={handleScroll}>
            <p></p>
            <>
                {/*{*/}
                {/*    toWork==1&&!firstLoading&&hotList.length > 0?(*/}
                {/*        <div className={styles.swiper}>*/}
                {/*            <p className={styles.titleTxt}>急聘职位</p>*/}
                {/*            /!*<img src={last} alt="" className={styles.lastImg} onClick={() => {card.current.prev()}}/>*!/*/}
                {/*            <aside>*/}
                {/*                <Swiper*/}
                {/*                    // install Swiper modules*/}
                {/*                    ref={swiperRef}*/}
                {/*                    modules={[Navigation,Autoplay]}*/}
                {/*                    slidesPerView={"auto"}*/}
                {/*                    centeredSlides={true}*/}
                {/*                    spaceBetween={50}*/}
                {/*                    navigation*/}
                {/*                    pagination={{ clickable: true }}*/}
                {/*                    autoplay={{*/}
                {/*                        delay: 3000,*/}
                {/*                    }}*/}
                {/*                    onSwiper={(swiper) => console.log(swiper)}*/}
                {/*                    onSlideChange={() => console.log('slide change')}*/}
                {/*                    className={styles.my_swiper} // 添加自定义的类名*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        hotList.length > 0 ? (*/}
                {/*                            hotList.map((item,index)=>(*/}
                {/*                                <SwiperSlide className={styles.my_slide} key={index}>*/}
                {/*                                    <div className={styles.swpierItem} onClick={() => routTo(item)}>*/}
                {/*                                        <div className={styles.itemTop}>*/}
                {/*                                            <div className={styles.topL}>*/}
                {/*                                                <p>*/}
                {/*                                                    {item.isUrgent === 1 && <img src={hotIcon} alt=""/>}*/}
                {/*                                                    {item.companyName ? item.companyName : '未填写'}*/}
                {/*                                                </p>*/}
                {/*                                                <h1>{item.positionName}</h1>*/}
                {/*                                            </div>*/}
                {/*                                            <div className={styles.topR}>*/}
                {/*                                                <img src={item.companyLogo} alt=""/>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                        <div className={styles.itemBottom}>*/}
                {/*                                            <b>*/}
                {/*                                                {item.employmentType === 3 && item.positionType === 1 && item.minSalary && item.maxSalary && (*/}
                {/*                                                    `${item.minSalary}-${item.maxSalary}元·${item.salaryMonths?item.salaryMonths:12}薪`*/}
                {/*                                                )}*/}
                {/*                                                {item.employmentType === 3 && item.positionType === 1 && item.discussPersonally === 1 && '面议'}*/}
                {/*                                                {item.employmentType === 3 && item.positionType !== 1 && item.partTimeSalary && (*/}
                {/*                                                    <React.Fragment>*/}
                {/*                                                        {item.partTimeSalary}*/}
                {/*                                                        {filters.getSalaryType(item.partTimeSalaryType)}*/}
                {/*                                                    </React.Fragment>*/}
                {/*                                                )}*/}
                {/*                                                {item.employmentType !== 3 && item.minSalary && item.maxSalary && `${item.minSalary}-${item.maxSalary}元/月/人`}*/}
                {/*                                            </b>*/}
                {/*                                            <p>{item.localhostText}</p>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </SwiperSlide>*/}
                {/*                            ))*/}
                {/*                        ) : ('')*/}
                {/*                    }*/}

                {/*                </Swiper>*/}
                {/*            </aside>*/}
                {/*            /!*<aside>*!/*/}
                {/*            /!*    <Carousel autoplay={true} dots={false} ref={card} >*!/*/}
                {/*            /!*        {*!/*/}
                {/*            /!*            hotList.length > 0 ? (*!/*/}
                {/*            /!*                hotList.map((item,index)=>(*!/*/}
                {/*            /!*                    <div className={styles.swpierItem} key={index} onClick={() => routTo(item)}>*!/*/}
                {/*            /!*                        <div className={styles.itemTop}>*!/*/}
                {/*            /!*                            <div className={styles.topL}>*!/*/}
                {/*            /!*                                <p>*!/*/}
                {/*            /!*                                    {item.isUrgent === 1 && <img src={hotIcon} alt=""/>}*!/*/}
                {/*            /!*                                    {item.companyName ? item.companyName : '未填写'}*!/*/}
                {/*            /!*                                </p>*!/*/}
                {/*            /!*                                <h1>{item.positionName}</h1>*!/*/}
                {/*            /!*                            </div>*!/*/}
                {/*            /!*                            <div className={styles.topR}>*!/*/}
                {/*            /!*                                <img src={item.companyLogo} alt=""/>*!/*/}
                {/*            /!*                            </div>*!/*/}
                {/*            /!*                        </div>*!/*/}
                {/*            /!*                        <div className={styles.itemBottom}>*!/*/}
                {/*            /!*                            <b>*!/*/}
                {/*            /!*                                {item.employmentType === 3 && item.positionType === 1 && item.minSalary && item.maxSalary && (*!/*/}
                {/*            /!*                                    `${item.minSalary}-${item.maxSalary}元·${item.salaryMonths}薪`*!/*/}
                {/*            /!*                                )}*!/*/}
                {/*            /!*                                {item.employmentType === 3 && item.positionType === 1 && item.discussPersonally === 1 && '面议'}*!/*/}
                {/*            /!*                                {item.employmentType === 3 && item.positionType !== 1 && item.partTimeSalary && (*!/*/}
                {/*            /!*                                    <React.Fragment>*!/*/}
                {/*            /!*                                        {item.partTimeSalary}*!/*/}
                {/*            /!*                                        {filters.getSalaryType(item.partTimeSalaryType)}*!/*/}
                {/*            /!*                                    </React.Fragment>*!/*/}
                {/*            /!*                                )}*!/*/}
                {/*            /!*                                {item.employmentType !== 3 && item.minSalary && item.maxSalary && `${item.minSalary}-${item.maxSalary}元/月/人`}*!/*/}
                {/*            /!*                            </b>*!/*/}
                {/*            /!*                            <p>{item.localhostText}</p>*!/*/}
                {/*            /!*                        </div>*!/*/}
                {/*            /!*                    </div>*!/*/}
                {/*            /!*                ))*!/*/}
                {/*            /!*            ) : ('')*!/*/}
                {/*            /!*        }*!/*/}
                {/*            /!*    </Carousel>*!/*/}
                {/*            /!*</aside>*!/*/}
                {/*            /!*<img src={next} alt="" className={styles.nextImg} onClick={() => {card.current.next()}}/>*!/*/}
                {/*        </div>*/}
                {/*    ):('')*/}
                {/*}*/}
                {/*{*/}
                {/*    toWork==1&&!firstLoading?(*/}
                {/*        <div className={styles.choose}>*/}
                {/*            <p>最新职位</p>*/}
                {/*            /!*<p className={chooseNum==1?styles.chooseActive:''} onClick={()=>changeChoose(1)}>最新</p>*!/*/}
                {/*            /!*<p className={chooseNum==2?styles.chooseActive:''} onClick={()=>changeChoose(2)}>附近</p>*!/*/}
                {/*        </div>*/}
                {/*    ):('')*/}
                {/*}*/}
            </>
            {
                toWork==1&&!firstLoading?(
                    <div className={styles.topChoose} style={{"width":"94%"}}>
                        <div className={styles.topBtn}>
                            <button onClick={()=>changeType(1)} className={chooseBtn==1?styles.btnActive:''}>全职</button>
                            <button onClick={()=>changeType(4)} className={chooseBtn==4?styles.btnActive:''}>急聘</button>
                            {/*<button onClick={()=>changeType(2)} className={chooseBtn==2?styles.btnActive:''}>零工/兼职</button>*/}
                            {/*<button onClick={()=>changeType(3)} className={chooseBtn==3?styles.btnActive:''}>实习</button>*/}

                        </div>
                        {
                            chooseBtn!=4?(
                                <div className={styles.chooseCon}>
                                    <p className={styles.chooseItem} onClick={handleArea}>
                                        {
                                            chooseCity?chooseCity:'地区'
                                        }
                                        <span></span>
                                    </p>
                                    <p className={styles.chooseItem} onClick={handleScreen}>
                                        筛选{chooseNum?chooseNum:''}
                                        <span></span>
                                    </p>
                                </div>
                            ):('')
                        }
                    </div>

                ):('')
            }

            {
                positionHotData.length > 0? (
                    <div className={toWork==1?`${styles.listScroll} ${styles.listScroll1}`:styles.listScroll} onClick={refreshTimer}>
                        <div className={styles.list} >
                            {positionHotData.map((item, index) => (
                                <article onClick={() => routTo(item)} key={index}>
                                    <div className={styles.listCon_title}>
                                        <section>
                                            <div className={styles.topTitle}>
                                                <h2 className={styles.headline}>{item.countyName} · {item.positionName}</h2>
                                                {item.isUrgent === 1 && <img src={hotIcon} alt="" />}
                                            </div>
                                            <div className={styles.salary}>
                                                <b>
                                                    {item.employmentType === 3 && item.positionType === 1 && item.minSalary && item.maxSalary && (
                                                        `${item.minSalary}-${item.maxSalary}元·${item.salaryMonths?item.salaryMonths:12}薪`
                                                    )}
                                                    {item.employmentType === 3 && item.positionType === 1 && item.discussPersonally === 1 && '面议'}
                                                    {item.employmentType === 3 && item.positionType !== 1 && item.partTimeSalary && (
                                                        <React.Fragment>
                                                            {item.partTimeSalary}
                                                            {filters.getSalaryType(item.partTimeSalaryType)}
                                                        </React.Fragment>
                                                    )}
                                                    {item.employmentType !== 3 && item.minSalary && item.maxSalary && `${item.minSalary}-${item.maxSalary}元/月/人`}
                                                </b>
                                            </div>
                                        </section>
                                        <aside>
                                            <section>
                                                <div className={styles.typeTxt}>
                                                    <p>{filters.getPotitionType(item.positionType)}</p>
                                                    <p>| {item.educationStr === '不限' ? '学历' + item.educationStr : item.educationStr}</p>
                                                    <p>| { filters.getExperience(item.workingLife) }</p>
                                                    <div className={styles.listCon_address}>
                                                        {item.subsidyLabel&&item.subsidyLabel.map((n, i) => (
                                                            i < 4 ? <p key={i}>{n !== '不限' && <span>{n}</span>}</p> : ''
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={styles.typeTxt}>
                                                    <p className={styles.companyName}>{item.companyName ? item.companyName : '未填写'}</p>
                                                    <div className={styles.btn}>
                                                        <button>报名 ></button>
                                                    </div>
                                                </div>
                                            </section>

                                        </aside>
                                    </div>

                                </article>
                            ))}
                        </div>
                        {
                            loading ? (
                                <div className={styles.loadingWrap}>
                                    <div className={styles.load_container}>
                                        <PageLoading></PageLoading>
                                    </div>
                                </div>
                            ) : ('')
                        }
                        <div className={styles.blockHeight}></div>
                    </div>
                ) : (
                    loading ? (
                        <div className={styles.loadingWrap}>
                            <div className={styles.load_container}>
                                <PageLoading></PageLoading>
                            </div>
                        </div>
                    ):(
                        <div className={styles.list}>
                            <img src={resumeDefault} alt="" />
                            <span>暂无数据</span>
                        </div>
                    )
                )
            }
            {/* {positionHotData.length > 0 && (
                <div className={styles.page}>
                    <button onClick={lastPage}>上一页</button>
                    <p>
                        { pageOption.pageNo == null ? 1 : (pageOption.pageNo + 1) } / { pageOption.pageCount }
                    </p>
                    <button onClick={nexPage}>下一页</button>
                </div>
            )} */}
        </div>
    );
};

export default PositionList;