import { combineReducers } from 'redux'
// 默认值
import defaultState from './state.js'


const timerType = (state = defaultState.timerType, action) => {
  // console.log(state, action)
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_TIMER_TYPE':
            return state = action.data
        case 'CLEAR_TIMER_TYPE':
            state = '';
            return state
        default:
            return state
    }
}

const timerData = (state = defaultState.timerData, action) => {
    // console.log(state, action)
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_TIMER':

            let json = {
                timerType: action.data.timerType,
                time: action.data.time,
                limit: action.data.time,
            }

            state = json;
            return state
        case 'CLEAR_TIMER':
            state.timerType = '';
            state.time = 0;
            state.limit = 0;
            let json1 = {
                timerType: '',
                time: 0,
                limit: 0,
            }

            state = json1;
            return state
        default:
            return state
    }
}

const time = (state = defaultState.timerData, action) => {
    // console.log(state, action)
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_TIME':
            return state.time = action.data
        default:
            return state
    }
}

const limit = (state = defaultState.timerData, action) => {
    // console.log(state, action)
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_LIMIT':
            return state.limit = action.data
        default:
            return state
    }
}

const broadKey = (state = defaultState.broadKey, action) => {

    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_BROAD_KEY':
            return state = action.data
        case 'CLEAR_BROAD_KEY':
            state = '';
            return state
        default:
            return state
    }
}

const audioAction = (state = defaultState.audioAction, action) => {

    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_AUDIO_ACTION':
            console.log(action)
            return state = action.data
        default:
            return state
    }
}

const chatRepId = (state = defaultState.chatRepId, action) => {

    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'SET_CHAT_REP_ID':
            return state = action.data
        case 'STOP_CHAT_REP_ID':
            return state = "stop"
        case 'CLEAR_CHAT_REP_ID':
            return state = ""
        default:
            return state
    }
}

const chatQueue = (state = defaultState.chatQueue, action) => {
    console.log(state, action)
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'ADD_CHAT_QUEUE':
            state.push(action.data)
            console.log(state)
            return state
        case 'REMOVE_CHAT_QUEUE':
            let tempIndex = -1;
            for(var i= 0; i < state.length; i++){
                let data = state[i];
                if(data.index === action.data){
                    tempIndex = i;
                }
            }
            state = state.slice(tempIndex,1)
            return state
        case 'REMOVE_ALL_CHAT_QUEUE':
            state = []
            return state
        case 'CLEAR_CHAT_QUEUE':
            return state = []
        default:
            return state
    }
}




// 导出所有reducer
export default combineReducers({
    timerType,
    time,
    limit,
    broadKey,
    audioAction,
    chatRepId,
    chatQueue
})