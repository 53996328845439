import axios from 'axios';
import { Toast } from 'antd-mobile';

let loading = false;

const axiosInstance = axios.create({
    timeout: 70000 // 设置超时时间为 5 秒
});

function jsonToUrlParams(json) {
    return Object.keys(json).map(key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
    ).join('&');
}

export const get = (url,data) => {

    if(data){
        var params = jsonToUrlParams(data);
        if(params){
            url = url + "?" + params
        }
    }
    
    // showLoading(); // 请求开始，显示loading

    return axiosInstance.get(url);
        // .finally(() => hideLoading());  // 返回axios的Promise对象
};

export const post = (url,data) => {

    // showLoading(); // 请求开始，显示loading


    return axiosInstance.post(url,data);
        // .finally(() => hideLoading());  // 返回axios的Promise对象
};

function showLoading() {
    loading = true;
    Toast.show({
        icon: 'loading',
        content: '加载中…',
        duration: 0,
    })
}

function hideLoading() {
    loading = false;
    Toast.clear();
}