import style from './aiLoading.module.less'
import SpeakLoading from "../speakLoading/SpeakLoading";
export function AiLoading() {
    return (
        <div className={style.con}>
            <div className={style.dot_spinner}>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
                <div className={style.dot_spinner__dot}></div>
            </div>
        </div>
    )
}
export default AiLoading;