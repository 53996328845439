import common from './common'

const filters = {
    getDateShowText: function(value,format) {
        if(value === undefined || value === null || value === ''){
            return "";
        }

        if(value === -999999999999){
			return "至今";
		}

        return common.formatDate(value,format);
    },

    getDefaultValue(value,defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }
        else{
            switch (value){
                case 1:
                    return "0-20人";
                case 2:
                    return "20-50人";
                case 3:
                    return "50-100人";
                case 4:
                    return "100-500人";
                case 5:
                    return "500人以上";
                default:
                    return defaultVal
            }
        }
    },
    
    getPotitionType: function(value,format) {
        if(value === undefined || value === null || value === ''){
            return "";
        }
        switch (value) {
            case 0: 
                return "其他";
            case 1:
                return "全职";
            case 2:
                return "兼职";
            case 3:
                return "零工";
            case 4:
                return "实习";
            default:
                break;
        }
    },
    //企业直招详情的
    getPotitionType1: function(value,format) {
        if(value === undefined || value === null || value === ''){
            return "";
        }
        switch (value) {
            case 0:
                return "全职";
            case 1:
                return "兼职";
            case 2:
                return "零工";
            case 3:
                return "实习";
            // case 4:
            //     return "实习";
            default:
                break;
        }
    },

    getSalaryType: function(value,format) {
        if(value === undefined || value === null || value === ''){
            return "";
        }
        switch (value) {
            case 0: 
                return "元/小时";
            case 1:
                return "元/天";
            case 2:
                return "元/一次性";
            case 3:
                return "元/月";
            default:
                break;
        }
    },

    getExperience(value, format, defaultVal) {
		if(value === undefined || value === null || value === ''){
            return "经验不限";
        }
		
		switch (value){
			case 0:
		        return '经验不限';
		    case 1:
		        return "一年以下";
		    case 2:
		        return "1-3年";
		    case 3:
		        return "3-5年";
		    case 4:
		        return "5-10年";
		    case 5:
		        return "10年以上";
		    default:
		        return '经验不限'
		}
	},

    //工作年限
    getWorkingLife(value){
        if(value === undefined || value === null || value === ''){
            return "";
        }

        switch (value){
            case 0:
                return "不限";
            case 1:
                return "一年以下";
            case 2:
                return "1-3年";
            case 3:
                return "3-5年";
            case 4:
                return "5-10年";
            case 5:
                return "十年以上";
            default:
                return "";
        }
    },

    getNatureText: function (value) {

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "其他";
            case 1:
                return "外商独资";
            case 2:
                return "中外合资";
            case 3:
                return "民营企业";
            case 4:
                return "国有企业";
            case 5:
                return "国内上市企业";
            case 6:
                return "政府机关/非营利机构";
            case 7:
                return "事业单位";
            default:
                return "";
        }
    },
}

export default filters;