import { get,post } from '../common/request.js';
const sgApi = "/api/"
class AiApi {
    createDigitalInterviewQr(params){
        let url = sgApi + "system/api/sangu/createDigitalInterviewQr";
        return get(url,params);
    }
    voiceToText(params){
        let url = sgApi + "system/api/sangu/voiceToText";
        return post(url,params);
    }
    //AI获取结果
    get_aiInterview_result(params) {
        let url = sgApi + "system/api/sangu/get_aiInterview_result";
        return get(url,params);
    }
}
export  {
    AiApi
};