export function setTimerType (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_TIMER_TYPE', data: data })
    }
}

export function setBroadKey (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_BROAD_KEY', data: data })
    }
}

export function clearBroadKey () {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_BROAD_KEY' })
    }
}

export function clearTimerType () {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_TIMER_TYPE' })
    }
}

export function setTimerData (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_TIMER', data: data })
    }
}

export function clearTimerData () {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_TIMER' })
    }
}

export function setTime (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_TIME', data: data })
    }
}

export function setLimit (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LIMIT', data: data })
    }
}

export function setTimer (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_TIMER', data: data })
    }
}
//  startAudio：开始收音 closeAudio：停止收音  receiveAudioText：接收到语音文字  sendAudioText: 发送语音文本
//  startBroadcast：开始播报 stopBroadcast：打断播报  endBroadcast：播报完成
export function setAudioAction (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_AUDIO_ACTION', data: data })
    }
}

export function setChatRepId (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CHAT_REP_ID', data: data })
    }
}

export function setStopChatRepId () {
    return (dispatch, getState) => {
        dispatch({ type: 'STOP_CHAT_REP_ID' })
    }
}

export function clearChatRepId () {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_CHAT_REP_ID' })
    }
}


export function addChatQueue (data) {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_CHAT_QUEUE', data: data })
    }
}

export function removeChatQueue (index) {
    return (dispatch, getState) => {
        dispatch({ type: 'REMOVE_CHAT_QUEUE',data: index })
    }
}

export function removeAllChatQueue () {
    return (dispatch, getState) => {
        dispatch({ type: 'REMOVE_ALL_CHAT_QUEUE' })
    }
}



export function clearChatQueue () {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_CHAT_QUEUE' })
    }
}

