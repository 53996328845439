import React, { useState, useEffect,useRef } from 'react';
import { Button } from 'react-vant';
import {PositionApi} from "../../services/positionApi.js";
import style from './area.module.less'

const Area = ({ areaData, onData, screenAreaParams}) => {
    const positionAi = new PositionApi();

    const [areaList, setAreaList] = useState([]);
    const [provinceData, setProvinceData] = useState({ value: '', label: '' });
    const [cityData, setCityData] = useState({ value: '', label: '' });
    const [countyData, setCountyData] = useState({ value: '', label: '' });


    
    useEffect(() => {
        getAreaList();
    }, [])

    // useEffect(() => {
    //     console.log(areaList);
    // }, [areaList])
    
    // 获取地址数据
    const getAreaList = () => {
        let params = {
            status:1,
            skipTokeCheck:1,
            indCode: localStorage.getItem('IND_CODE'),
            digEsSearch: 1, // 是否是数字人搜索 1是 2否
            pageNo: 0,
			pageSize: 50,
            isDisabled: 0,
            positionTypeInt: screenAreaParams.positionTypeInt == 3 ? 4 : screenAreaParams.positionTypeInt,
            educationInt: screenAreaParams.educationInt,
            workingLifeInt: screenAreaParams.workingLifeInt,
            salaryInt: screenAreaParams.salaryInt,
            locationCode: screenAreaParams.locationCode,
        }
        positionAi.getLabourPositionCityAndCount(params).then(res => {
            if(res.data.code == 200 || res.data.code == '200') {
                setAreaList(res.data.data);

                // console.log(areaData, '父组件传参');
                if(areaData.province.label) {
                    setProvinceData({
                        value: areaData.province.value,
                        label: areaData.province.label
                    })
                    setCityData({
                        value: areaData.city.value,
                        label: areaData.city.label
                    })
                    setCountyData({
                        value: areaData.county.value,
                        label: areaData.county.label
                    })
                }else {

                    setProvinceData({
                        value: res.data.data[0].value,
                        label: res.data.data[0].label
                    })
                    setCityData({
                        value: res.data.data[0].children[0].value,
                        label: res.data.data[0].children[0].label
                    })
                    setCountyData({
                        value: res.data.data[0].children[0].children[0].value,
                        label: res.data.data[0].children[0].children[0].label
                    })
                }
                
            }
        })
    }

    // 点击省级
    const handleProvinces = (val) => {
        setProvinceData({
            value: val.value,
            label: val.label
        });

        setCityData({
            value: val.children[0].value,
            label: val.children[0].label
        });

        if(val.children[0].children) {
            setCountyData({
                value: val.children[0].children[0].value,
                label: val.children[0].children[0].label
            });
        }else {
            setCountyData({
                value: '',
                label: ''
            });
        }

    }

    // 点击 市级
    const handleCitys = (val) => {
        setCityData({
            value: val.value,
            label: val.label
        });

        if(val.children) {
            setCountyData({
                value: val.children[0].value,
                label: val.children[0].label
            });
        }else {
            setCountyData({
                value: '',
                label: ''
            });
        }
    }

    // 点击区级
    const handleCountys = (val) => {
        setCountyData({
            value: val.value,
            label: val.label
        });
    }

    // 点击清除
    const handleClean = () => {
        setProvinceData({
            value: areaList[0].value,
            label: areaList[0].label
        });

        setCityData({
            value: areaList[0].children[0].value,
            label: areaList[0].children[0].label
        });

        if(areaList[0].children[0].children) {
            setCountyData({
                value: areaList[0].children[0].children[0].value,
                label: areaList[0].children[0].children[0].label
            });
        }
    }

    // 点击确定
    const handleConfirm = () => {
        onData(provinceData, cityData, countyData);
    }
    
    return (
        <div className={style.areaWrap}>
            <div className={style.list}>

                {/* 省级 */}
                <div className={`${style.province_list} ${style.areaList}`}>
                    {areaList.map(province => {
                        return (
                            <div 
                                className={ province.label == provinceData.label ? `${style.item} ${style.province_active}` : style.item}
                                key={province.label}
                                onClick={() => handleProvinces(province)}
                            >{ province.label }</div>
                        )
                    })}
                </div>
                
                {/* 市级 */}
                <div className={`${style.city_list} ${style.areaList}`}>
                    {areaList.map(province => {
                        return (
                            province.label == provinceData.label && (
                                province.children.map(city => {
                                    return (
                                        <div 
                                            className={ city.label == cityData.label ? `${style.item} ${style.city_active}` : style.item}
                                            key={city.label}
                                            onClick={() => handleCitys(city)}
                                        >{ city.label }</div>
                                    )
                                })
                            )
                        )
                    })}
                </div>

                {/* 区级 */}
                <div className={`${style.county_list} ${style.areaList}`}>
                    {areaList.map(province => {
                        return (
                            province.label == provinceData.label && (
                                province.children.map(city => {
                                    return (
                                        city.children && (
                                            city.label == cityData.label && (
                                                city.children.map(county => {
                                                    return (
                                                        <div 
                                                            className={ county.label == countyData.label ? `${style.item} ${style.city_county}` : style.item}
                                                            key={county.label}
                                                            onClick={() => handleCountys(county)}
                                                        >{ county.label }</div>
                                                    )
                                                })
                                            )
                                        )
                                    )
                                })
                            )
                        )
                    })}
                </div>

            </div>
            <div className={style.bottom}>
                <Button type='primary' className={style.cleanBtn} onClick={handleClean}>清除</Button>
                <Button type='primary' className={style.confirmBtn} onClick={handleConfirm}>确定</Button>
            </div>
        </div>
    );
};

export default Area;