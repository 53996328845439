import style from './pageLoading.module.less'

function pageLoading(){
    return(
        <div className={style.pageCenter}>
            <div className={style.loader}>
                <div className={style.circle}></div>
                <div className={style.circle}></div>
                <div className={style.circle}></div>
                <div className={style.circle}></div>
            </div>
        </div>
    )
}
export default pageLoading