import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"

class SgAuthApi{

    async sendVerfyCode(mobile){

        let data = {
            authType: "",
            email: "",
            mobile: mobile,
            userId: 0,
            verifyType: 2
        }

        var url = api + "system/api/sangu/sendIotSignUpVerifyCode";
        console.log(url,data,'opo')
        return post(url,data);
    }

    async verifyCode(mobile,code){

        let data = {
            authType: 8,
            code: code,
            mobile: mobile
        }

        var url = api + "system/api/sangu/verifyCode"
            // "?authType=8&code="+ code +"&mobile="+mobile;
        return get(url,data);
    }

    //获取用户信息
    getUserInfo(params){
        var url = api + "system/digital/auth/decrypt/info";
        return get(url,params);
    }

}

export  {
    SgAuthApi
};