import style from './speakLoading.module.less'
function SpeakLoading() {
    return(
        <div className={style.loading}>
            <span className={style.item}></span>
            <span className={style.item}></span>
            <span className={style.item}></span>
            <span className={style.item}></span>
            <span className={style.item}></span>
        </div>
    )
}
export default SpeakLoading;